<template>
  <div
    ref="tooltipContainer"
    @mouseover="showToolTip(true)"
    @mouseleave="showToolTip(false)"
    class="tooltip-container"
  >
    <span ref="infoIcon" class="info-icon">&#9432;</span>
    <div
      v-show="show"
      ref="tooltip"
      :class="['tooltip', { [pseudoMod]: pseudoMod !== '' }]"
      @mouseleave="showToolTip(false)"
    >
      <p>{{ text }}</p>
    </div>
  </div>
</template>

<script lang="ts">
import { Ref, ref, nextTick } from "vue";

export default {
  name: "ToolTip",
  props: {
    text: {
      type: String,
      required: true,
    },
  },
  setup() {
    const show = ref(false);
    const tooltip: Ref<HTMLElement | null> = ref(null);
    const infoIcon: Ref<HTMLElement | null> = ref(null);
    const tooltipContainer: Ref<HTMLElement | null> = ref(null);
    const pseudoMod: Ref<string> = ref("");

    function showToolTip(value: boolean) {
      const app = document.getElementById("app");
      show.value = value;

      nextTick(() => {
        if (value && tooltip.value != null && infoIcon.value != null) {
          const { scrollHeight } = document.body;
          const { x, y, bottom } = infoIcon.value.getBoundingClientRect();
          const { offsetHeight } = tooltip.value;
          const tooltipRect = tooltip.value.getBoundingClientRect();

          const tooltipBottom = tooltipRect.height + (y - (offsetHeight - 20) / 2);

          if (tooltipBottom < scrollHeight) {
            tooltip.value.style.top = `${y - (offsetHeight - 20) / 2}px`;
            tooltip.value.style.bottom = "";
            pseudoMod.value = "";
          } else {
            tooltip.value.style.bottom = "0px";
            tooltip.value.style.top = "";
            const arrowOffset = (scrollHeight - bottom) / tooltipRect.height;

            if (arrowOffset < 0.05) {
              pseudoMod.value = "five";
            } else if (arrowOffset < 0.1) {
              pseudoMod.value = "ten";
            } else if (arrowOffset < 0.15) {
              pseudoMod.value = "fifteen";
            } else if (arrowOffset < 0.2) {
              pseudoMod.value = "twenty";
            } else if (arrowOffset < 0.25) {
              pseudoMod.value = "twenty-five";
            } else if (arrowOffset < 0.3) {
              pseudoMod.value = "thirty";
            } else if (arrowOffset < 0.35) {
              pseudoMod.value = "thirty-five";
            } else if (arrowOffset < 0.4) {
              pseudoMod.value = "forty";
            } else if (arrowOffset < 0.45) {
              pseudoMod.value = "forty-five";
            } else if (arrowOffset < 0.5) {
              pseudoMod.value = "fifty";
            } else if (arrowOffset < 0.55) {
              pseudoMod.value = "fifty-five";
            } else if (arrowOffset < 0.6) {
              pseudoMod.value = "sixty";
            } else if (arrowOffset < 0.65) {
              pseudoMod.value = "sixty-five";
            } else if (arrowOffset < 0.7) {
              pseudoMod.value = "seventy";
            } else if (arrowOffset < 0.75) {
              pseudoMod.value = "seventy-five";
            } else if (arrowOffset < 0.8) {
              pseudoMod.value = "eighty";
            } else if (arrowOffset < 0.85) {
              pseudoMod.value = "eighty-five";
            } else if (arrowOffset < 0.9) {
              pseudoMod.value = "ninety";
            } else if (arrowOffset < 0.95) {
              pseudoMod.value = "ninety-five";
            }
          }

          tooltip.value.style.left = `${x + 36}px`;
          app?.appendChild(tooltip.value);
        } else if (!value && tooltip.value != null) {
          tooltipContainer.value?.appendChild(tooltip.value);
        }
      });
    }

    return { show, showToolTip, tooltip, infoIcon, tooltipContainer, pseudoMod };
  },
};
</script>

<style lang="scss">
.tooltip-container {
  padding-left: 4px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: help;

  .info-icon {
    font-size: 20px;
  }
}

.tooltip {
  position: absolute;
  width: 240px;
  padding: 10px 14px;
  background-color: white;
  border-radius: 3px;
  border: 2px dashed black;
  z-index: 1000;

  &::after {
    content: " ";
    position: absolute;
    top: 50%;
    right: 100%;
    margin-top: -5px;
    border-width: 6px;
    border-style: solid;
    border-color: transparent black transparent transparent;
  }

  &.five::after {
    top: unset;
    bottom: 5%;
  }
  &.ten::after {
    top: unset;
    bottom: 10%;
  }
  &.fifteen::after {
    top: unset;
    bottom: 15%;
  }
  &.twenty::after {
    top: unset;
    bottom: 20%;
  }
  &.twenty-five::after {
    top: unset;
    bottom: 25%;
  }
  &.thirty::after {
    top: unset;
    bottom: 30%;
  }
  &.thirty-five::after {
    top: unset;
    bottom: 35%;
  }
  &.forty::after {
    top: unset;
    bottom: 40%;
  }
  &.forty-five::after {
    top: unset;
    bottom: 45%;
  }
  &.fifty::after {
    top: unset;
    bottom: 50%;
  }
  &.fifty-five::after {
    top: unset;
    bottom: 55%;
  }
  &.sixty::after {
    top: unset;
    bottom: 60%;
  }
  &.sixty-five::after {
    top: unset;
    bottom: 65%;
  }
  &.seventy::after {
    top: unset;
    bottom: 70%;
  }
  &.seventy-five::after {
    top: unset;
    bottom: 75%;
  }
  &.eighty::after {
    top: unset;
    bottom: 80%;
  }
  &.eighty-five::after {
    top: unset;
    bottom: 85%;
  }
  &.ninety::after {
    top: unset;
    bottom: 90%;
  }
  &.ninety-five::after {
    top: unset;
    bottom: 95%;
  }
}
</style>
