import { defineStore } from "pinia";
import { ComponentPublicInstance, Ref, ref } from "vue";

export const useErrorsStore = defineStore("errors", () => {
  const errors: Ref<
    {
      message: string;
      stack: string | undefined;
      component: string | undefined;
      info: string;
    }[]
  > = ref([]);
  const otherInfo = {
    userAgent: navigator.userAgent,
  };

  function addError(err: unknown, vm: ComponentPublicInstance | null, info: string) {
    if (err instanceof Error) {
      errors.value.push({
        message: err.message,
        stack: err.stack,
        component: vm?.$options.name,
        info,
      });
    }
  }

  function getDownloadableJson(e: typeof errors.value) {
    return JSON.stringify({
      otherInfo,
      errors: e,
      localStorage: localStorage,
    });
  }

  return {
    addError,
    errors,
    getDownloadableJson,
  };
});
