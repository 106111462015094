import { defineStore } from "pinia";
import { computed, ComputedRef, ref } from "vue";

import { Entity, useGameStore } from "@/store/game";
import { useUpgradesStore } from "@/store/upgrades";
import { give } from "@/util";

export interface CumCurrency extends Entity {
  maxObtained: number;
  max: ComputedRef<number>;
  maxLabel?: ComputedRef<string>;
  onTick?(tick: number): void;
  give: (amount: number) => void;
  onGainCallbacks: { [key: string]: (numGained: number) => void };
}

export type CumCurrencyKey = "cums" | "cumProgress" | "cumMotes" | "cumCoin";

export const useCurrenciesStore = defineStore("currencies", () => {
  const currencies: { [key: string]: CumCurrency } = {
    cums: {
      name: "Cums",
      infoText: "Cum is the main currency",
      amount: 0,
      maxObtained: 0,
      max: computed(() => {
        const { upgrades } = useUpgradesStore();

        if (upgrades.cumJar.amount === 2) return 1000000000;
        if (upgrades.cumJar.amount === 1) return 1000000;

        return 1000;
      }),
      maxLabel: computed(() => {
        const { upgrades } = useUpgradesStore();

        if (upgrades.cumJar.amount === 2) return "Cum Jar: Giga Vat";
        if (upgrades.cumJar.amount === 1) return "Cum Jar: Gallon Jug";

        return "Cum Jar: Pickle";
      }),
      isVisible: computed(() => true),
      purchase(amount: number) {
        const { upgrades } = useUpgradesStore();
        const { currencies } = useCurrenciesStore();

        this.give(amount);

        if (
          this.amount === 1000000000 &&
          currencies.cumMotes.amount < Number(currencies.cumMotes.max) &&
          upgrades.autoCumdense.amount === 1 &&
          upgrades.autoCumdense.active
        ) {
          this.amount = 0;
          currencies.cumMotes.give(1);
        }
      },
      give: give("currencies.cums"),
      onGainCallbacks: {},
    },
    cumProgress: {
      name: "Climax Progress",
      infoText: "Climax Progress",
      amount: 0,
      maxObtained: 0,
      max: computed(() => 100),
      isVisible: computed(() => false),
      onTick(tick: number) {
        const { tickRateFactor } = useGameStore();

        if (tick % 8 === 0) {
          const loss = tickRateFactor * (3 + Math.random());
          this.amount = Math.max(0, this.amount - loss);
        }
      },
      purchase(amount: number) {
        const { upgrades } = useUpgradesStore();
        const { currencies } = useCurrenciesStore();

        const cumsPerClimax = Math.pow(2, upgrades.doubleClimax.amount);
        if (upgrades.lotion.amount === 1) amount *= 2;
        if (upgrades.lotion.amount === 2 && amount < 69) amount = 69;

        const newAmount = this.amount + amount;

        if (newAmount >= 100) {
          const climaxTimes = Math.floor(newAmount / 100);

          currencies.cums.purchase(cumsPerClimax * climaxTimes);
          Object.values(currencies.cumProgress.onGainCallbacks).forEach((callback) => {
            callback(cumsPerClimax * climaxTimes);
          });

          this.amount = 0;
        } else {
          this.amount += amount;
        }
      },
      give: give("currencies.cumProgress"),
      onGainCallbacks: {},
    },
    cumMotes: {
      name: "Cum Motes",
      infoText: "Cum motes are cum condensed into a powerful and pure essence",
      amount: 0,
      maxObtained: 0,
      max: computed(() => {
        const { upgrades } = useUpgradesStore();

        if (upgrades.cumMoteContainment.amount === 3) return 1_000_000_000;
        if (upgrades.cumMoteContainment.amount === 2) return 1_000_000;
        if (upgrades.cumMoteContainment.amount === 1) return 1_000;

        return 20;
      }),
      maxLabel: computed(() => {
        const { upgrades } = useUpgradesStore();

        if (upgrades.cumMoteContainment.amount === 3) return "Mote Containment: Black Hole";
        if (upgrades.cumMoteContainment.amount === 2) return "Mote Containment: Dimensional";
        if (upgrades.cumMoteContainment.amount === 1) return "Mote Containment: Mystical";

        return "Mote Containment: None";
      }),
      isVisible: computed(() => {
        const { currencies } = useCurrenciesStore();

        return currencies.cumMotes.maxObtained > 0;
      }),
      purchase(amount: number) {
        this.give(amount);
      },
      give: give("currencies.cumMotes"),
      onGainCallbacks: {},
    },
    cumCoin: {
      name: "Cum Coin",
      infoText:
        "Cum Coin is the crypto currency of the future! All major online sex toy shops accept Cum Coin!",
      amount: 0,
      maxObtained: 0,
      max: computed(() => {
        const { upgrades } = useUpgradesStore();

        if (upgrades.cumCoinWallet.amount === 3) return 1_000_000_000;
        if (upgrades.cumCoinWallet.amount === 2) return 1_000_000;
        if (upgrades.cumCoinWallet.amount === 1) return 1_000;

        return 5;
      }),
      maxLabel: computed(() => {
        const { upgrades } = useUpgradesStore();

        if (upgrades.cumCoinWallet.amount === 3) return "Cum Coin Wallet: Data Center";
        if (upgrades.cumCoinWallet.amount === 2) return "Cum Coin Wallet: NAS";
        if (upgrades.cumCoinWallet.amount === 1) return "Cum Coin Wallet: External HDD";

        return "Cum Coin Wallet: Flash Drive";
      }),
      isVisible: computed(() => {
        const { currencies } = useCurrenciesStore();

        return currencies.cumCoin.maxObtained > 0;
      }),
      purchase(amount: number) {
        this.give(amount);
      },
      give: give("currencies.cumCoin"),
      onGainCallbacks: {},
    },
  };

  return { currencies: ref(currencies) };
});
