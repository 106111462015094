import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-ccd70230"),n=n(),_popScopeId(),n)
const _hoisted_1 = { id: "game-container" }
const _hoisted_2 = { id: "game-display" }
const _hoisted_3 = { class: "button-container" }
const _hoisted_4 = { class: "progress-container" }
const _hoisted_5 = { class: "events-panel" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SideBar = _resolveComponent("SideBar")!
  const _component_BlinkyText = _resolveComponent("BlinkyText")!
  const _component_StrokeItProgressBar = _resolveComponent("StrokeItProgressBar")!

  return (_openBlock(), _createElementBlock("section", _hoisted_1, [
    _createVNode(_component_SideBar),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_BlinkyText, { message: "Welcome to Cum Clicker" }),
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("button", {
            onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.handleStroke && _ctx.handleStroke(...args)))
          }, "Stroke it"),
          _createTextVNode("   "),
          _createVNode(_component_StrokeItProgressBar, {
            progress: _ctx.cumProgress.amount
          }, null, 8, ["progress"])
        ])
      ]),
      _createElementVNode("div", _hoisted_5, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.eventsStream, (event, index) => {
          return (_openBlock(), _createElementBlock("span", {
            key: `${index}.${event}`
          }, _toDisplayString(event), 1))
        }), 128))
      ])
    ])
  ]))
}