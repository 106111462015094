import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, vShow as _vShow, normalizeClass as _normalizeClass, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  ref: "infoIcon",
  class: "info-icon"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    ref: "tooltipContainer",
    onMouseover: _cache[1] || (_cache[1] = ($event: any) => ($setup.showToolTip(true))),
    onMouseleave: _cache[2] || (_cache[2] = ($event: any) => ($setup.showToolTip(false))),
    class: "tooltip-container"
  }, [
    _createElementVNode("span", _hoisted_1, "ⓘ", 512),
    _withDirectives(_createElementVNode("div", {
      ref: "tooltip",
      class: _normalizeClass(['tooltip', { [$setup.pseudoMod]: $setup.pseudoMod !== '' }]),
      onMouseleave: _cache[0] || (_cache[0] = ($event: any) => ($setup.showToolTip(false)))
    }, [
      _createElementVNode("p", null, _toDisplayString($props.text), 1)
    ], 34), [
      [_vShow, $setup.show]
    ])
  ], 544))
}