import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"
import _imports_0 from '@/assets/borpa-3x.png'
import _imports_1 from '@/assets/gayge-3x.gif'


const _withScopeId = n => (_pushScopeId("data-v-4e9cf569"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "borpa",
  src: _imports_0,
  alt: "borpa"
}
const _hoisted_2 = {
  key: 1,
  class: "gayge",
  src: _imports_1,
  alt: "gayge"
}
const _hoisted_3 = {
  key: 2,
  class: "borpa left",
  src: _imports_0,
  alt: "borpa"
}
const _hoisted_4 = {
  key: 3,
  class: "gayge left",
  src: _imports_1,
  alt: "gayge"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("nav", {
    class: _normalizeClass({ gayge: _ctx.cheatsActive.gayge })
  }, [
    _createTextVNode(" * BETA *  "),
    (!_ctx.cheatsActive.gayge)
      ? (_openBlock(), _createElementBlock("img", _hoisted_1))
      : (_openBlock(), _createElementBlock("img", _hoisted_2)),
    _createTextVNode(" Cum Clicker lole "),
    (!_ctx.cheatsActive.gayge)
      ? (_openBlock(), _createElementBlock("img", _hoisted_3))
      : (_openBlock(), _createElementBlock("img", _hoisted_4)),
    _createTextVNode("  * BETA * ")
  ], 2))
}