<template>
  <section id="game-container">
    <SideBar />
    <div id="game-display">
      <div class="button-container">
        <BlinkyText message="Welcome to Cum Clicker" />
        <div class="progress-container">
          <button @click="handleStroke">Stroke it</button>
          &nbsp;
          <StrokeItProgressBar :progress="cumProgress.amount" />
        </div>
      </div>
      <div class="events-panel">
        <span v-for="(event, index) in eventsStream" :key="`${index}.${event}`">
          {{ event }}
        </span>
      </div>
    </div>
  </section>
</template>

<script lang="ts">
import { defineComponent } from "vue";

import { useCurrenciesStore } from "@/store/currencies";
import { useEventsStore } from "@/store/events";
import { useGameStore } from "@/store/game";
import StrokeItProgressBar from "@/components/Game/StrokeItProgressBar.vue";
import SideBar from "@/components/Game/SideBar.vue";
import BlinkyText from "@/components/common/BlinkyText.vue";

export default defineComponent({
  name: "HelloWorld",
  components: {
    SideBar,
    BlinkyText,
    StrokeItProgressBar,
  },
  setup() {
    const {
      currencies: { cumProgress },
    } = useCurrenciesStore();
    const { eventsStream } = useEventsStore();
    const { currentSession } = useGameStore();

    function handleStroke() {
      const sessionStrokes = localStorage.getItem(`sessions.${currentSession}.strokes`);
      localStorage.setItem(
        `sessions.${currentSession}.strokes`,
        String(Number(sessionStrokes) + 1),
      );
      cumProgress.purchase(8 + Math.random() * 2);
    }

    return { cumProgress, eventsStream, handleStroke };
  },
});
</script>

<style scoped lang="scss">
#game-container {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  #game-display {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .button-container {
      flex-grow: 1;
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      .progress-container {
        display: flex;
        margin-left: 120px;
        align-items: center;
      }
    }

    .events-panel {
      width: 100%;
      height: 180px;
      border-top: 1px dashed black;
      overflow-y: scroll;
      display: flex;
      flex-direction: column;

      span {
        padding: 4px;
      }
    }
  }
}
</style>
