import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "modal-container"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ErrorModal = _resolveComponent("ErrorModal")!
  const _component_CostModal = _resolveComponent("CostModal")!
  const _component_SessionStatsModal = _resolveComponent("SessionStatsModal")!

  return ($setup.cheatsActive.cost || $setup.showErrorModal || $setup.cheatsActive.session)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        ($setup.showErrorModal)
          ? (_openBlock(), _createBlock(_component_ErrorModal, {
              key: 0,
              onCancel: _cache[0] || (_cache[0] = () => ($setup.showErrorModal = false))
            }))
          : ($setup.cheatsActive.cost)
            ? (_openBlock(), _createBlock(_component_CostModal, { key: 1 }))
            : ($setup.cheatsActive.session)
              ? (_openBlock(), _createBlock(_component_SessionStatsModal, { key: 2 }))
              : _createCommentVNode("", true)
      ]))
    : _createCommentVNode("", true)
}