import { defineStore } from "pinia";
import { computed, ComputedRef, Ref, ref } from "vue";

import { useCurrenciesStore } from "@/store/currencies";
import { useBuildingsStore } from "@/store/buildings";
import { useGameStore } from "@/store/game";
import { TickEffect, useUnitsStore } from "@/store/units";
import { useUpgradesStore } from "@/store/upgrades";
import { formatNum, getStoreFromType } from "@/util";

type CumEvent = {
  eventText: (amounts: (number | string)[]) => string;
  isPossible: ComputedRef<boolean>;
  baseChance: number;
  scalable: boolean;
  baseReward: TickEffect[];
  trigger: () => void;
};

function getChanceRange(
  percentChance: number,
  rangeStart: number,
  modifier: number,
): [number, number] {
  const percentAsNumber = (percentChance / 100) * modifier;
  const rangeEnd = rangeStart + percentAsNumber;
  const range: [number, number] = [rangeStart, rangeEnd];

  return range;
}

function isInRange(rand: number, range: [number, number]): boolean {
  return rand >= range[0] && rand < range[1];
}

export const useEventsStore = defineStore("events", () => {
  const eventsStream: Ref<string[]> = ref([]);
  const nextEvent: Ref<number> = ref(0);

  function addEventStream(event: string) {
    const now = new Date();
    const time = now.toLocaleTimeString("en-US", {
      hour: "2-digit",
      minute: "2-digit",
    });

    eventsStream.value.unshift(`${time}: ${event}`);
    eventsStream.value.length = Math.min(20, eventsStream.value.length);
  }

  function clearEventsStream() {
    eventsStream.value.length = 0;
  }

  function trigger(this: CumEvent) {
    const gains: (number | string)[] = [];

    this.baseReward.forEach((reward) => {
      const { type, item, max, min } = reward;
      const store = getStoreFromType(type);
      const entity = store[item];

      let amount = Math.random() * (max - min) + min;

      if (this.scalable && "maxObtained" in entity) {
        const { amount: currentAmount, maxObtained } = entity;

        amount += Math.random() * (maxObtained * 0.01 - maxObtained * 0.005) + maxObtained * 0.005;
        amount +=
          Math.random() * (currentAmount * 0.02 - currentAmount * 0.01) + currentAmount * 0.01;
      }

      gains.push(amount % 1 === 0 ? amount : formatNum(amount));
      store[item].give(amount);
    });

    addEventStream(this.eventText(gains));
  }

  const events: { [key: string]: CumEvent } = {
    // Unit Recruit events
    // 0.25% baseChance
    borpaMinerRecruit: {
      eventText: ([miners]) =>
        `A borpa walks up to you, he volunteers himself to work in your mines. You gained ${miners} Borpa Miners`,
      isPossible: computed(() => {
        const {
          buildings: { cumMines },
        } = useBuildingsStore();
        const {
          units: { borpaMiners },
        } = useUnitsStore();

        return cumMines.amount > 0 && borpaMiners.amount < Number(borpaMiners.max);
      }),
      baseChance: 0.25,
      scalable: false,
      baseReward: [
        {
          type: "units",
          item: "borpaMiners",
          min: 1,
          max: 1,
        },
      ],
      trigger,
    },
    borpaWorkerRecruit: {
      eventText: ([workers]) =>
        `A borpa walks up to you, he volunteers himself to work in your factories. You gain ${workers} Borpa Workers`,
      isPossible: computed(() => {
        const {
          buildings: { cumFactories },
        } = useBuildingsStore();
        const {
          units: { borpaWorkers },
        } = useUnitsStore();

        return cumFactories.amount > 0 && borpaWorkers.amount < Number(borpaWorkers.max);
      }),
      baseChance: 0.25,
      scalable: false,
      baseReward: [
        {
          type: "units",
          item: "borpaWorkers",
          min: 1,
          max: 1,
        },
      ],
      trigger,
    },
    borpaGardenerRecruit: {
      eventText: ([gardeners]) =>
        `A borpa walks up to you, he volunteers himself to work in your gardens. You gain ${gardeners} Borpa Gardeners`,
      isPossible: computed(() => {
        const {
          buildings: { cumGardens },
        } = useBuildingsStore();
        const {
          units: { borpaGardeners },
        } = useUnitsStore();

        return cumGardens.amount > 0 && borpaGardeners.amount < Number(borpaGardeners.max);
      }),
      baseChance: 0.25,
      scalable: false,
      baseReward: [
        {
          type: "units",
          item: "borpaGardeners",
          min: 1,
          max: 1,
        },
      ],
      trigger,
    },
    borpaGamerRecruit: {
      eventText: ([gamers]) =>
        `A Borpa walks up to you wearing the nintendo power glove and shows you his micro. He sheepishly asks if he can use one of your 10K PCs. You welcome him aboard. You gain ${gamers} Borpa Gamers`,
      isPossible: computed(() => {
        const {
          buildings: { tenKPcs },
        } = useBuildingsStore();
        const {
          units: { borpaGamers },
        } = useUnitsStore();

        return tenKPcs.amount > 0 && borpaGamers.amount < Number(borpaGamers.max);
      }),
      baseChance: 0.25,
      scalable: false,
      baseReward: [
        {
          type: "units",
          item: "borpaGamers",
          min: 1,
          max: 1,
        },
      ],
      trigger,
    },
    borpaCoomerRecruit: {
      eventText: ([coomers]) =>
        `You find a sad, shriveled up borpa on the side of the road, he's stroking his shit but nothing is coming out. "Coooom...", he wheezes. The poor thing loves cooming so much it neglected to stay hydrated. You take him back to your compound and hook him up to an IV drop. He's back to cooming in no time. "Cooom!". You gain ${coomers} Borpa Coomers`,
      isPossible: computed(() => {
        const {
          buildings: { onlycumsSubscriptions },
        } = useBuildingsStore();
        const {
          units: { borpaCoomers },
        } = useUnitsStore();

        return onlycumsSubscriptions.amount > 0 && borpaCoomers.amount < Number(borpaCoomers.max);
      }),
      baseChance: 0.25,
      scalable: false,
      baseReward: [
        {
          type: "units",
          item: "borpaCoomers",
          min: 1,
          max: 1,
        },
      ],
      trigger,
    },
    borpaMysticRecruit: {
      eventText: ([mystics]) =>
        `While on a walk in the forest, you stumble upon a borpa portal. Something isn't quite right. The portal is sputtering, and you can hear a faint voice. Looking around you notice a couple of ritualistic pylons that have been knocked over. You set them up right and the portal solidifies! Out pops a frantic borpa mystic. "I would have been stuck in the coom realm if you hadn't come by! Damn squirrels! I'm forever in your debt!". You take him to your mystic bunker to join the ranks. You gain ${mystics} Borpa Mystics`,
      isPossible: computed(() => {
        const {
          buildings: { summoningCircles },
        } = useBuildingsStore();
        const {
          units: { borpaMystics },
        } = useUnitsStore();

        return summoningCircles.amount > 0 && borpaMystics.amount < Number(borpaMystics.max);
      }),
      baseChance: 0.25,
      scalable: false,
      baseReward: [
        {
          type: "units",
          item: "borpaMystics",
          min: 1,
          max: 1,
        },
      ],
      trigger,
    },
    cumboElementalRecruit: {
      eventText: ([elementals]) =>
        `While helping your cousin with a construction job, a small cum spring was discovered after knocking out some concrete in the parking lot. You start collecting some of the cum to add to the pile back home, but something is different about this cum. You scoop it up, and it slides out your container and rejoins the spring. After excavating a bit more of the parking lot you reveal whats going on. This is a wild Cumbo Elemental! You call up your top Borpa Mystic and tell him to bring you an elemental bracelet stat! He arrives and you are able to wrangle the elemental without much trouble. You gain ${elementals} Cumbo Elementals`,
      isPossible: computed(() => {
        const {
          buildings: { elementalBracelets },
        } = useBuildingsStore();
        const {
          units: { cumboElementals },
        } = useUnitsStore();

        return (
          elementalBracelets.amount > 0 && cumboElementals.amount < Number(cumboElementals.max)
        );
      }),
      baseChance: 0.25,
      scalable: false,
      baseReward: [
        {
          type: "units",
          item: "cumboElementals",
          min: 1,
          max: 1,
        },
      ],
      trigger,
    },
    borpaRecruiterRecruit: {
      eventText: ([recruiters]) =>
        `Every other night you hear about another tech company laying off 10%. Now might be a good time to scoop up some talent at a reasonable salary. You check coomedIn and a wall of the most disingenuous blather assaults your eyes. Bad advice and motivation porn starts scrambling your mind, your IQ drops rapidly. A moment of clarity stops you from sharing the dumbest article on AI crypto you've ever seen. Among the drivel you spot a hurried and desperate post from a newly laid off borpa recruiter. He's willing to work for 3 cums a week! You quickly fire off a coomedIn coomMail and arrange a series of pointless hoops for the recruiter; borpa recruiters fucking love pointless hoops. After the paperwork is filed, and the start date arrives, you gain ${recruiters} Borpa Recruiters`,
      isPossible: computed(() => {
        const {
          buildings: { WFHSetups },
        } = useBuildingsStore();
        const {
          units: { borpaRecruiters },
        } = useUnitsStore();

        return WFHSetups.amount > 0 && borpaRecruiters.amount < Number(borpaRecruiters.max);
      }),
      baseChance: 0.25,
      scalable: false,
      baseReward: [
        {
          type: "units",
          item: "borpaRecruiters",
          min: 1,
          max: 1,
        },
      ],
      trigger,
    },
    cumboGenieRecruit: {
      eventText: ([genies]) =>
        `While walking through a forest, you notice a small mouse sheepishly peeking around the base of a tree. When it realizes it has been noticed, it pauses for a moment and then comes out and walks out in front of you, facing a southward direction. You think it wants you to follow it! You oblige and walk along its path for a while. Eventually you come across an abandoned wooden chest. The mouse scurries on top, and lets out three squeaks. You walk over and open the chest, inside is a lot of dust and a clear phial with a swirling cloud inside. You pick up the phial and look around for the mouse, but it seems to have scurried away while you were distracted. In your head, you hear a faint raspy voice repeatedly say "Break the phial....". You think for a moment and can't recall anything like this when you were studying evil random occurrences in high school, so this must be a positive one or at least neutral. You take out your trusty ball peen hammer and break the phial against a flat rock. The cloud escapes the phial and dissipates. You wait for a moment, expecting something to come from it, but nothing happens. You turn around to leave and get back on the path, and when you do you see a massive cumbo genie! "Thank you for freeing me from that phial!", it says. The mouse, you notice, is sitting on the genie's shoulder. "I know about your mission to strike it rich in cums. I pledge myself to help you succeed in that for the small amount of time you mortals stay alive". It doesn't feel great to be reminded of your mortality, but being the master of a cumbo genie will be incredible in the meantime. You gain ${genies} cumbo genies`,
      isPossible: computed(() => {
        const {
          buildings: { genieLamps },
        } = useBuildingsStore();
        const {
          units: { cumboGenies },
        } = useUnitsStore();

        return genieLamps.amount > 0 && cumboGenies.amount < Number(cumboGenies.max);
      }),
      baseChance: 0.25,
      scalable: false,
      baseReward: [
        {
          type: "units",
          item: "cumboGenies",
          min: 1,
          max: 1,
        },
      ],
      trigger,
    },
    // Upgrade events
    // Nothing
    // 1% baseChance
    greenPillOne: {
      eventText: () => "Someone compliments you on your nice tasting cum. Nice!",
      isPossible: computed(() => {
        const {
          upgrades: { littleGreenPill },
        } = useUpgradesStore();

        return littleGreenPill.amount > 0;
      }),
      baseChance: 1,
      scalable: false,
      baseReward: [],
      trigger,
    },
    // 75-150 Cums
    // 0.75% baseChance
    greenPillTwo: {
      eventText: ([cums]) =>
        `After an unfortunate accident at the dog park, a bystander walks up to you and enquires how your cum got so minty. Upon informing them about the little green pill, they take out a small cum jar and gives it to you as thanks. When you get home, you count out and add ${cums} cums to your jar!`,
      isPossible: computed(() => {
        const {
          upgrades: { littleGreenPill },
        } = useUpgradesStore();

        return littleGreenPill.amount > 0;
      }),
      baseChance: 0.75,
      scalable: true,
      baseReward: [
        {
          type: "currencies",
          item: "cums",
          min: 75,
          max: 150,
        },
      ],
      trigger,
    },
    // Currency events
    // Cums
    // 1 Cums
    // 10% baseChance
    steppedOnCum: {
      eventText: () =>
        "You were on a walk and stepped on a cum. You scraped it off your shoe and added it to your jar. +1 cums",
      isPossible: computed(() => true),
      baseChance: 10,
      scalable: false,
      baseReward: [
        {
          type: "currencies",
          item: "cums",
          min: 1,
          max: 1,
        },
      ],
      trigger,
    },
    // 10-20 Cums
    // 5% baseChance
    foundCumJar: {
      eventText: ([cums]) =>
        `You turned a corner and found an abandoned cum jar. There's still some cum inside! You added the ${cums} cums to your cum jar.`,
      isPossible: computed(() => true),
      baseChance: 5,
      scalable: true,
      baseReward: [
        {
          type: "currencies",
          item: "cums",
          min: 10,
          max: 20,
        },
      ],
      trigger,
    },
    // 10-20 Cums
    // 5% baseChance
    cumPhantom: {
      eventText: ([cums]) =>
        `Someone taps you on the shoulder, but when you turn around, its just cum? You scrape up the ${cums} cums and add them to your cum jar.`,
      isPossible: computed(() => true),
      baseChance: 5,
      scalable: true,
      baseReward: [
        {
          type: "currencies",
          item: "cums",
          min: 10,
          max: 20,
        },
      ],
      trigger,
    },
    // 40-80 Cums
    // 5% baseChance
    cumRift: {
      eventText: ([cums]) =>
        `You were stroking it and a rift opened above your head. Cum starting pouring out. You managed to catch ${cums} cums in your jar`,
      isPossible: computed(() => true),
      baseChance: 0.5,
      scalable: true,
      baseReward: [
        {
          type: "currencies",
          item: "cums",
          min: 40,
          max: 80,
        },
      ],
      trigger,
    },
    // 5-10 Cums
    // 2.5% baseChance
    cumGross: {
      eventText: ([cums]) =>
        `While eating out your mom, you taste something familiar. It's cum! You quickly slurp it up and spit what you can gather into your cum jar. You manage to salvage ${cums} cums`,
      isPossible: computed(() => true),
      baseChance: 2.5,
      scalable: true,
      baseReward: [
        {
          type: "currencies",
          item: "cums",
          min: 5,
          max: 10,
        },
      ],
      trigger,
    },
    // 25-50 Cums
    // 2.5% baseChance
    cumFart: {
      eventText: ([cums]) =>
        `You fart but it feels like it was a bit more than that. You check your underwear later and carefully collect the cum that hasn't slid out. You manage to scrape up ${cums} cums`,
      isPossible: computed(() => true),
      baseChance: 2.5,
      scalable: true,
      baseReward: [
        {
          type: "currencies",
          item: "cums",
          min: 25,
          max: 50,
        },
      ],
      trigger,
    },
    // 5-10 Cums
    // 2% baseChance
    cumShoe: {
      eventText: ([cums]) =>
        `You get home and it feels like theres something in your shoe. You take them off and look inside. Somehow a few cums found their way in there. You shake out the ${cums} cums and add them to your jar`,
      isPossible: computed(() => true),
      baseChance: 2,
      scalable: true,
      baseReward: [
        {
          type: "currencies",
          item: "cums",
          min: 5,
          max: 10,
        },
      ],
      trigger,
    },
    // 50-100 Cums
    // 1.333% baseChance
    cumSwap: {
      eventText: ([cums]) =>
        `You go to the weekly cum swap and find some good deals. You bought and sold a few things and managed to make a small profit. You get home and count out to be ${cums} cums ahead`,
      isPossible: computed(() => true),
      baseChance: 1.333,
      scalable: true,
      baseReward: [
        {
          type: "currencies",
          item: "cums",
          min: 50,
          max: 100,
        },
      ],
      trigger,
    },
    // 50-100 Cums
    // 1.333% baseChance
    cumDream: {
      eventText: ([cums]) =>
        `Its bed time and you are exhausted. Upon falling asleep you start having some crazy dreams. One you are on a raft floating in an endless sea of cum. Another you are performing in a local play and cum keeps falling out of your pockets. Another you have found the cure for cum cancer and are awarded the Lasker Award. The dreams get weirder and weirder and you eventually awake drenched in cummy sweat. You eventually get up and scrape off the cum sweat to add it to your jar. +${cums} cums`,
      isPossible: computed(() => true),
      baseChance: 1.333,
      scalable: true,
      baseReward: [
        {
          type: "currencies",
          item: "cums",
          min: 50,
          max: 100,
        },
      ],
      trigger,
    },
    // 0 Cums
    // 1% baseChance
    noCum: {
      eventText: ([cums]) =>
        `It felt good like usual. But when you go to collect it, there's nothing there! No mess, nothing sticky, nothing wet, no residue, nothing! Maybe you should see a doctor. +${cums} cums`,
      isPossible: computed(() => true),
      baseChance: 1,
      scalable: false,
      baseReward: [
        {
          type: "currencies",
          item: "cums",
          min: 0,
          max: 0,
        },
      ],
      trigger,
    },
    // 200-300 Cums
    // 1% baseChance
    cupOCum: {
      eventText: ([cums]) =>
        `While taking your cat to the vet, you help yourself to a cup of coffee. One sip is all it takes to notice something is awry. You look down in the cup, it's cum! You start sticking the cum in your pockets to take home while you look for a real coffee machine. +${cums} cums`,
      isPossible: computed(() => true),
      baseChance: 1,
      scalable: true,
      baseReward: [
        {
          type: "currencies",
          item: "cums",
          min: 200,
          max: 300,
        },
      ],
      trigger,
    },
    // 1000-2000 Cums
    // 1% baseChance
    cumVein: {
      eventText: ([cums]) =>
        `While toiling away, one of your borpa miners struck a rich vein of cum! You quickly organize a team of your best borpa miners to start mining it out. Oh boy its a deep one! The team works hard to harvest every bit of the vein. When all is said and done, you are ${cums} cums richer. You organize a pizza party for the miners`,
      isPossible: computed(() => {
        const { units } = useUnitsStore();
        return units.borpaMiners.amount > 0;
      }),
      baseChance: 1,
      scalable: true,
      baseReward: [
        {
          type: "currencies",
          item: "cums",
          min: 1000,
          max: 2000,
        },
      ],
      trigger,
    },
    // 1000-2000 Cums
    // 1% baseChance
    hiddenTreasure: {
      eventText: ([cums]) =>
        `One of your Borpa Gardeners found something interesting while weeding their garden. A small and old looking coin, with an image of a stern looking borpa on the front, and a bowl of cum grapes on the back. You send it to the Museum of Natural Cums to get it checked out. They date it from the age of the great Borpa Cumgyar Empire! You contact a collector who eagerly purchases the coin at a fair price. The check arrives in the mail and you are ${cums} cums richer`,
      isPossible: computed(() => {
        const { units } = useUnitsStore();
        return units.borpaGardeners.amount > 0;
      }),
      baseChance: 1,
      scalable: true,
      baseReward: [
        {
          type: "currencies",
          item: "cums",
          min: 1000,
          max: 2000,
        },
      ],
      trigger,
    },
    // 10000-20000 Cums
    // 0.5% baseChance
    theTI: {
      eventText: ([cums]) =>
        `It's that time of the year, the TI! The most illustrious e-sports tournament by far. Your hand picked team of Borpa Gamers has a fair shot at first prize this year. They start knocking out teams one by one. They are dominating, but some games are still close. Until at last, the grand final. Both teams walk out onto the stage. The players awkwardly wave to the camera before making their way to their PCs. The most epic match of all time unfolds before your eyes. Jungle is ganked by both sides repeatedly, but they still make their blink dagger timings. Towers are ratted one by one. Cumshan's resurrection scroll is claimed by both teams as they try and force fights. At last, the only building standing for either team is their cumcient. One last incredible battle. Both sides buy back multiple times. In the end only one player is left standing. Ah fuck! It's an enemy player! They quickly navigate their hero to the wide open cumcient and wack away at it. There's no chances left. Your team lost! You are removing addy privileges for a month! Oh well, second prize is still good. You gain ${cums} cums, and a neat looking trophy`,
      isPossible: computed(() => {
        const { units } = useUnitsStore();
        return units.borpaGamers.amount > 4;
      }),
      baseChance: 1,
      scalable: true,
      baseReward: [
        {
          type: "currencies",
          item: "cums",
          min: 10000,
          max: 20000,
        },
      ],
      trigger,
    },
    // 50-500 Cums
    // 0.5% baseChance
    cumvalanche: {
      eventText: ([cums]) =>
        `While on a skiing trip down cum mountain, you've been caught in a cumvalanche! It takes a bit to dig yourself out, but when you're safe back home you collect the cum that stuck to you. You gather ${cums} cums!`,
      isPossible: computed(() => true),
      baseChance: 0.5,
      scalable: true,
      baseReward: [
        {
          type: "currencies",
          item: "cums",
          min: 50,
          max: 500,
        },
      ],
      trigger,
    },
    // Minus 100-1000 Cums
    // 0.5% baseChance
    cumBandit: {
      eventText: ([cums]) =>
        `While you weren't looking, a cum bandit helped himself to your cum jar! You notice ${cums} missing cums!`,
      isPossible: computed(() => true),
      baseChance: 0.5,
      scalable: true,
      baseReward: [
        {
          type: "currencies",
          item: "cums",
          min: -100,
          max: -1000,
        },
      ],
      trigger,
    },
    // 250-500 Cums
    // 0.3555% baseChance
    cumSecretSanta: {
      eventText: ([cums]) =>
        `You join a secret santa and put only one thing on your list: cum. When you receive your present you looks inside and its... cum! You add the ${cums} cums to your jar`,
      isPossible: computed(() => true),
      baseChance: 0.3555,
      scalable: true,
      baseReward: [
        {
          type: "currencies",
          item: "cums",
          min: 250,
          max: 500,
        },
      ],
      trigger,
    },
    // 500-1000 Cums
    // 0.2% baseChance
    cumAssassin: {
      eventText: ([cums]) =>
        `On your evening walk you can't shake the feeling that you are being watched... You try and serpentine through the city streets just in case and try to throw off anyone on your trail. That is until you turn a corner and see a cum assassin! He spends no time explaining and attacks! Luckily you've trained all your life in CQC but it was still a mighty struggle til the end. You stand over your slain opponent and run his pockets. You take out ${cums} cums and add them to your jar`,
      isPossible: computed(() => true),
      baseChance: 0.2,
      scalable: true,
      baseReward: [
        {
          type: "currencies",
          item: "cums",
          min: 500,
          max: 1000,
        },
      ],
      trigger,
    },
    // 500-5000 Cums
    // 0.125% baseChance
    cumInherit: {
      eventText: ([cums]) =>
        `You've received word that your great great uncle has passed away, he's left you a small fortune in cums. You receive in the mail a week later ${cums} cums!`,
      isPossible: computed(() => true),
      baseChance: 0.125,
      scalable: true,
      baseReward: [
        {
          type: "currencies",
          item: "cums",
          min: 500,
          max: 5000,
        },
      ],
      trigger,
    },
    // 25000-50000 Cums
    // 0.025% baseChance
    cumHacker: {
      eventText: ([cums]) =>
        `You spend your evening carefully navigating the network and system security of the World Bank of Cum. When you feel confident you transfer some cum out of one of their larger client's accounts. In and out, none the wiser. You wake up the next day ${cums} cums richer`,
      isPossible: computed(() => true),
      baseChance: 0.025,
      scalable: true,
      baseReward: [
        {
          type: "currencies",
          item: "cums",
          min: 25000,
          max: 50000,
        },
      ],
      trigger,
    },
    // MAX CUMS
    // 0.001% baseChance
    cumEvolve: {
      eventText: () =>
        "Its time. Its time to go even further beyond. This climax will go down in history. You focus intently on your cock and start stroking. You find the perfect rhythm. Your favorite strokin song fills the air. A woman brushed against you on the bus today, at least you think it was a woman. The pressure is building, you can feel it coming. Your balls start pulsing. It's imminent, nothing can stop it now. The GIGA CUM OF HELL. Everything comes to a head. You feel your balls draining at a rapid pace. Channels inside opening up letting the epic levels of cum find its way to its exit. And then it happens. The fluid comes out with great intensity. Your body is being physically pushed back as the cum leaves you. It takes all your might to direct the stream into your jar. You cum for the next four hours. It almost kills you. You pass out from exhaustion. When you awaken, you peel yourself out of the layer of cum deposited on the floor and discover you've completely filled your cum jar.",
      isPossible: computed(() => true),
      baseChance: 0.001,
      scalable: false,
      baseReward: [
        {
          type: "currencies",
          item: "cums",
          min: 1000000000,
          max: 1000000000,
        },
      ],
      trigger,
    },
    // Cum Motes
    // 1-2 cumMotes
    // 2% baseChance
    motePhantom: {
      eventText: ([cumMotes]) =>
        `Someone taps you on the shoulder, but when you turn around, its just a pure and powerful mote of cum essence? You bandage up the searing hole it burned into your flesh when it made contact, and carefully collect the ${cumMotes} cum motes and add them to your mote containment`,
      isPossible: computed(() => {
        const { currencies } = useCurrenciesStore();
        return currencies.cumMotes.maxObtained > 0;
      }),
      baseChance: 2,
      scalable: true,
      baseReward: [
        {
          type: "currencies",
          item: "cumMotes",
          min: 1,
          max: 2,
        },
      ],
      trigger,
    },
    // 2-5 cumMotes
    // 1% baseChance
    alienVessel: {
      eventText: ([cumMotes]) =>
        `While out in your cum field, you notice something bright fall from the sky and... *BOOM*.. crash nearby! You grab your fles...flashlight and head out. You quickly navigate to the column of smoke in the distance. Holy fuck! It's a fucking alien flying saucer! The green borpa-like inhabitants have sadly not survived the crash. Upon further inspection, it seems their craft was powered by cum motes! While you call up your buddy at the DoD, you help yourself to some of the motes around the crash site... +${cumMotes} cum motes`,
      isPossible: computed(() => {
        const { currencies } = useCurrenciesStore();
        return currencies.cumMotes.maxObtained > 0;
      }),
      baseChance: 1,
      scalable: true,
      baseReward: [
        {
          type: "currencies",
          item: "cumMotes",
          min: 2,
          max: 5,
        },
      ],
      trigger,
    },
  };

  function onTick(tick: number) {
    if (tick >= nextEvent.value) {
      // Choose and do event
      const { events } = useEventsStore();
      const possibleEvents: CumEvent[] = [];
      let baseChanceTotal = 0;

      Object.values(events).forEach((event) => {
        if (event.isPossible) {
          possibleEvents.push(event);
          baseChanceTotal += event.baseChance;
        }
      });

      const chanceModifier = 100 / baseChanceTotal;
      const rand = Math.random();
      let rangeStart = 0;

      for (let i = 0; i < possibleEvents.length; i += 1) {
        const event = possibleEvents[i];
        const [min, max] = getChanceRange(event.baseChance, rangeStart, chanceModifier);
        rangeStart = max;

        if (isInRange(rand, [min, max])) {
          event.trigger();
          break;
        }
      }

      setNextEventTick(tick);
    }
  }

  function setNextEventTick(currentTick: number) {
    const { tickRate } = useGameStore();

    // const randomMilliseconds = (Math.random() * (2 - 1) + 1) * 1000;
    const randomMilliseconds = (Math.random() * (120 - 30) + 30) * 1000;
    const ticks = Math.floor(randomMilliseconds / tickRate);

    nextEvent.value = currentTick + ticks;
  }

  function updateTickRate(oldTickRate: number, newTickRate: number, tick: number) {
    const ticksUntilEvent = nextEvent.value - tick;
    const scaledTicksUntilEvent = (ticksUntilEvent * newTickRate) / oldTickRate;
    nextEvent.value = Math.floor(tick + scaledTicksUntilEvent);
  }

  return {
    addEventStream,
    clearEventsStream,
    events: ref(events),
    eventsStream,
    nextEvent,
    onTick,
    setNextEventTick,
    updateTickRate,
  };
});
