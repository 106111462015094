<template>
  <div :class="['progress-bar-container', { gayge: cheatsActive.gayge }]">
    <div :class="['progress-bar', { gayge: cheatsActive.gayge }]" :style="barPosition"></div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { useCheatsStore } from "@/store/cheats";
import { storeToRefs } from "pinia";

export default defineComponent({
  name: "ProgressBar",
  props: {
    progress: {
      type: Number,
      required: true,
    },
  },
  setup() {
    const cheatsStore = useCheatsStore();
    const { cheatsActive } = storeToRefs(cheatsStore);

    return { cheatsActive };
  },
  computed: {
    barPosition() {
      return `right: ${100 - this.progress}%`;
    },
  },
});
</script>

<style scoped lang="scss">
.progress-bar-container {
  height: 24px;
  width: 120px;
  background-color: white;
  border: 2px solid black;
  overflow-x: hidden;

  &.gayge {
    border-image: linear-gradient(
      to bottom right,
      #b827fc 0%,
      #2c90fc 25%,
      #44cd00 50%,
      #fef137 75%,
      #fd1892 100%
    );
    border-image-slice: 1;
  }

  .progress-bar {
    height: 100%;
    width: 100%;
    transition: right 0.05s linear;
    background-color: black;
    position: relative;

    &.gayge {
      background: linear-gradient(
        90deg,
        rgba(255, 0, 0, 0.8) 0%,
        rgba(255, 154, 0, 0.8) 10%,
        rgba(208, 222, 33, 0.8) 20%,
        rgba(79, 220, 74, 0.8) 30%,
        rgba(63, 218, 216, 0.8) 40%,
        rgba(47, 201, 226, 0.8) 50%,
        rgba(28, 127, 238, 0.8) 60%,
        rgba(95, 21, 242, 0.8) 70%,
        rgba(186, 12, 248, 0.8) 80%,
        rgba(251, 7, 217, 0.8) 90%,
        rgba(255, 0, 0, 0.8) 100%
      );
    }
  }
}
</style>
