import { App } from "vue";
import { formatNum } from "@/util";

declare module "@vue/runtime-core" {
  export interface ComponentCustomProperties {
    $formatNum: (num: number) => string;
  }
}

export default {
  install: (app: App) => {
    app.config.globalProperties.$formatNum = formatNum;
  },
};
