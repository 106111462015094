<template>
  <div class="modal">
    <div class="error-modal">
      <div class="heading">
        <h1>Error!</h1>
        <span @click="$emit('cancel')">X</span>
      </div>
      <p>
        If you would be so kind, please download this
        <a :href="errorsString" download="errors.json">error report</a> and send to
        <a href="mailto:jay@jaysull.dev">jay@jaysull.dev</a>
      </p>
      <template v-for="error in errors.slice(0, 5)" :key="error.stack">
        <div class="error">
          <h1>{{ error.message }}</h1>
          Stack:
          <pre>{{ error.stack }}</pre>
          <p>Component: {{ error.component }}</p>
          <p>Error: {{ error.info }}</p>
        </div>
      </template>
    </div>
  </div>
</template>

<script lang="ts">
import { useErrorsStore } from "@/store/errors";
import { computed } from "vue";

export default {
  name: "ErrorModal",
  setup() {
    const { errors, getDownloadableJson } = useErrorsStore();

    const errorsString = computed(() => {
      return `data:text/json;charset=utf-8,${encodeURIComponent(getDownloadableJson(errors))}`;
    });

    return { errors, errorsString };
  },
};
</script>

<style lang="scss">
.error-modal {
  width: 600px;
  height: 100%;
  overflow-y: scroll;
  padding-right: 16px;

  .heading {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-right: 4px;

    span {
      padding: 8px;
      cursor: pointer;
    }
  }

  h1 {
    margin-bottom: 10px;
    margin-top: 10px;
    font-size: 20px;
    font-weight: 600;
  }

  .error {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;

    pre {
      margin-top: 4px;
      margin-left: 4px;
      white-space: pre-line;
      line-height: 1.3;
      font-family: monospace;
    }

    p {
      margin-top: 10px;
    }
  }
}
</style>
