<template>
  <NavBar />
  <main :class="{ gayge: cheatsActive.gayge }">
    <router-view />
  </main>
  <FooterBar />
  <ModalContainer />
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { useGameStore } from "@/store/game";
import NavBar from "@/components/NavBar.vue";
import FooterBar from "@/components/FooterBar.vue";
import ModalContainer from "@/components/common/Modal/ModalContainer.vue";
import { useCheatsStore } from "@/store/cheats";

export default defineComponent({
  name: "App",
  components: {
    NavBar,
    FooterBar,
    ModalContainer,
  },
  setup() {
    const { init } = useGameStore();
    const { cheatsActive } = useCheatsStore();
    init();

    return { cheatsActive };
  },
});
</script>

<style lang="scss">
@import "./assets/reset.css";

@font-face {
  font-family: "IBMPlexMono";
  src:
    local("IBMPlexMono"),
    url("./assets/IBMPlexMono-Regular.ttf") format("truetype");
}

html,
body,
#app {
  height: 100vh;
  width: 100vw;
  font-family: IBMPlexMono;
}

main {
  height: calc(100% - 66px);
}

button {
  background-color: white;
  border: 2px solid black;
  padding: 4px;
  border-radius: 1px;
  margin: 2px 0;
}

.gayge {
  button {
    border-image: linear-gradient(
      to bottom right,
      #b827fc 0%,
      #2c90fc 25%,
      #44cd00 50%,
      #fef137 75%,
      #fd1892 100%
    );
    border-image-slice: 1;
  }
}
</style>
