import { createApp } from "vue";
import { createPinia } from "pinia";
import formatPlugin from "@/util/formatPlugin";
import App from "@/App.vue";
// import "./registerServiceWorker";
import router from "@/router";
import { useErrorsStore } from "./store/errors";
const pinia = createPinia();

const app = createApp(App).use(router).use(pinia).use(formatPlugin);

app.config.errorHandler = (err, vm, info) => {
  const { addError } = useErrorsStore();

  addError(err, vm, info);
};

app.mount("#app");
