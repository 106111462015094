<template>
  <nav :class="{ gayge: cheatsActive.gayge }">
    * BETA *&nbsp;
    <img v-if="!cheatsActive.gayge" class="borpa" src="@/assets/borpa-3x.png" alt="borpa" />
    <img v-else class="gayge" src="@/assets/gayge-3x.gif" alt="gayge" />
    Cum Clicker lole
    <img v-if="!cheatsActive.gayge" class="borpa left" src="@/assets/borpa-3x.png" alt="borpa" />
    <img v-else class="gayge left" src="@/assets/gayge-3x.gif" alt="gayge" />
    &nbsp;* BETA *
  </nav>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { storeToRefs } from "pinia";
import { useCheatsStore } from "@/store/cheats";

export default defineComponent({
  name: "NavBar",
  setup() {
    const cheatsStore = useCheatsStore();
    const { cheatsActive } = storeToRefs(cheatsStore);

    return { cheatsActive };
  },
});
</script>

<style scoped lang="scss">
nav {
  height: 32px;
  width: 100%;
  border-bottom: 1px black dashed;
  display: flex;
  align-items: center;
  justify-content: center;

  .borpa {
    height: 24px;
    margin: 0 8px 8px 8px;
  }

  .gayge {
    height: 18px;
    margin: 0 8px;
  }

  .left {
    transform: rotateY(180deg);
  }

  &.gayge {
    background: linear-gradient(
      90deg,
      rgba(255, 0, 0, 0.4) 0%,
      rgba(255, 154, 0, 0.4) 10%,
      rgba(208, 222, 33, 0.4) 20%,
      rgba(79, 220, 74, 0.4) 30%,
      rgba(63, 218, 216, 0.4) 40%,
      rgba(47, 201, 226, 0.4) 50%,
      rgba(28, 127, 238, 0.4) 60%,
      rgba(95, 21, 242, 0.4) 70%,
      rgba(186, 12, 248, 0.4) 80%,
      rgba(251, 7, 217, 0.4) 90%,
      rgba(255, 0, 0, 0.4) 100%
    );
  }
}
</style>
