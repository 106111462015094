<template>
  <div class="blinky-text">{{ msg }}<span class="carrot">_</span></div>
</template>

<script lang="ts">
import { defineComponent, ref } from "vue";

export default defineComponent({
  name: "BlinkyText",
  props: {
    message: {
      required: true,
      type: String,
    },
  },
  setup() {
    const msg = ref("");
    return { msg };
  },
  mounted() {
    let index = 0;
    let hadTypo = false;

    const letters = [
      "a",
      "b",
      "c",
      "d",
      "e",
      "f",
      "g",
      "h",
      "i",
      "j",
      "k",
      "l",
      "m",
      "n",
      "o",
      "p",
      "q",
      "r",
      "s",
      "t",
      "u",
      "v",
      "w",
      "x",
      "y",
      "z",
    ];

    const processMessage = () => {
      if (this.message[index] == null) return;

      if (index > 0 && !hadTypo && Math.random() > 0.8) {
        hadTypo = true;
        let randomLetter = letters[Math.floor(Math.random() * letters.length)];

        while (randomLetter === this.message[index]) {
          randomLetter = letters[Math.floor(Math.random() * letters.length)];
        }

        if (this.message[index].toLowerCase() !== this.message[index]) {
          this.msg += randomLetter.toUpperCase();
        } else {
          this.msg += randomLetter;
        }

        setTimeout(
          () => {
            this.msg = this.msg.slice(0, -1);

            setTimeout(
              () => {
                processMessage();
              },
              100 + Math.random() * 100,
            );
          },
          200 + Math.random() * 100,
        );
      } else {
        hadTypo = false;
        this.msg += this.message[index];
        index++;

        setTimeout(() => processMessage(), 100 + Math.random() * 100);
      }
    };

    processMessage();
  },
});
</script>

<style lang="scss">
@keyframes blink-animation {
  0% {
    opacity: 0;
  }

  50% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

.blinky-text {
  height: 96px;
  width: auto;
  display: flex;
  align-items: center;
  justify-content: center;

  .carrot {
    animation: blink-animation step-end 1s infinite;
  }
}
</style>
