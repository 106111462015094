<template>
  <div class="container">
    <ProgressBar :progress="cumProgress.amount" />
    <div class="gain-wrapper">
      <template v-for="(gain, index) of gainedCums" :key="`${gain}-${index}`">
        <span v-if="gain" class="gain">+{{ gain }} Cums!</span>
      </template>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, Ref, ref } from "vue";
import { useCurrenciesStore } from "@/store/currencies";
import ProgressBar from "@/components/common/ProgressBar.vue";
import { wait } from "@/util";

export default defineComponent({
  name: "StrokeItProgressBar",
  components: {
    ProgressBar,
  },
  props: {
    progress: {
      type: Number,
      required: true,
    },
  },
  setup() {
    const {
      currencies: { cumProgress },
    } = useCurrenciesStore();
    const gainedCums: Ref<{ [key: string]: number | undefined }> = ref({});

    const numCumProgressCallbacks = Object.keys(cumProgress.onGainCallbacks).length;
    const callBackKey = `StrokeItProgressBar-${numCumProgressCallbacks + 1}`;

    let gainIndex = 0;
    cumProgress.onGainCallbacks[callBackKey] = async function (numGained: number) {
      const thisIndex = gainIndex;
      gainIndex++;
      if (gainIndex > 100) gainIndex = 0;
      gainedCums.value[thisIndex] = numGained;

      await wait(1000);
      gainedCums.value[thisIndex] = undefined;
    };

    return {
      cumProgress,
      callBackKey,
      gainedCums,
    };
  },
  beforeUnmount() {
    delete this.cumProgress.onGainCallbacks[this.callBackKey];
  },
});
</script>

<style scoped lang="scss">
@keyframes liftAndDissapear {
  from {
    opacity: 3;
    margin-bottom: 0;
  }
  to {
    opacity: 0;
    margin-bottom: 30px;
  }
}
.container {
  height: 28px;
  width: 250px;
  display: flex;

  .gain-wrapper {
    width: 120px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    right: 0;

    .gain {
      width: 120px;
      display: flex;
      align-items: center;
      justify-content: center;
      animation: liftAndDissapear 0.8s linear forwards;
      position: absolute;
    }
  }
}
</style>
