<template>
  <span>
    <span v-for="(part, index) in stringParts" :key="`${index}.${part.digit}`" :class="part.class">
      {{ part.digit }}
    </span>
  </span>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { useCheatsStore } from "@/store/cheats";
import { storeToRefs } from "pinia";

export default defineComponent({
  name: "RainbowDigits",
  props: {
    number: {
      type: [Number, String],
      required: true,
    },
  },
  computed: {
    stringParts() {
      const cheatsStore = useCheatsStore();
      const { cheatsActive } = storeToRefs(cheatsStore);
      const parts: { digit: string; class: string }[] = [];
      const numberAsString = String(this.number);

      let classDigit = numberAsString.length - 1;

      for (let i = 0; i < numberAsString.length; i += 1) {
        parts.push({
          digit: numberAsString[i],
          class: cheatsActive.value.gayge ? `digit-${classDigit}` : "",
        });

        classDigit--;
      }

      const decimalPosition = numberAsString.includes(".")
        ? numberAsString.split(".")[0].length - 1
        : numberAsString.length - 1;

      for (let i = decimalPosition, j = 0; i >= 0; i -= 1, j += 1) {
        if (j === 2 && i > 0) {
          parts[i].digit = "," + parts[i].digit;
          j = -1;
        }
      }

      return parts;
    },
  },
});
</script>

<style scoped lang="scss">
.digit-0 {
  color: #840000;
}

.digit-1 {
  color: #7c3e00;
}

.digit-2 {
  color: #818100;
}

.digit-3 {
  color: #468c00;
}

.digit-4 {
  color: #007f00;
}

.digit-5 {
  color: #008241;
}

.digit-6 {
  color: #008080;
}

.digit-7 {
  color: #003e7c;
}

.digit-8 {
  color: #00007f;
}

.digit-9 {
  color: #3d007a;
}

.digit-10 {
  color: #750075;
}

.digit-11 {
  color: #710038;
}

.digit-12 {
  color: #840000;
}
</style>
