import { defineStore } from "pinia";
import { Ref, ref } from "vue";
import { useCurrenciesStore } from "@/store/currencies";

export const useCheatsStore = defineStore("cheats", () => {
  const { currencies } = useCurrenciesStore();
  const cheatCode: Ref<string> = ref("");
  const cheatCodeEventActive: Ref<boolean> = ref(false);
  const cheatsActive: Ref<{ [key: string]: boolean }> = ref({
    gayge: false,
    tickRate: false,
    cost: false,
    session: false,
  });

  function addCheatCodeEvent() {
    if (cheatCodeEventActive.value === false) {
      cheatCodeEventActive.value = true;

      const cheatsListener = (event: KeyboardEvent) => {
        const { key } = event;
        const lowerCase = key.toLowerCase();

        if (/[a-z]/.test(lowerCase)) {
          cheatCode.value += lowerCase;
        }

        if (cheatsActive.value.gayge === false && cheatCode.value.includes("gayge")) {
          console.log("Gayge mode ACTIVATED");
          cheatsActive.value.gayge = true;
          cheatCode.value = "";
        } else if (cheatsActive.value.gayge === true && cheatCode.value.includes("straightge")) {
          console.log("Straightge mode ACTIVATED");
          cheatsActive.value.gayge = false;
          cheatCode.value = "";
        }

        if (cheatCode.value.includes("timewizard")) {
          console.log("Time Wizard mode ACTIVATED");
          cheatsActive.value.tickRate = !cheatsActive.value.tickRate;
          cheatCode.value = "";
        }

        if (cheatCode.value.includes("cum")) {
          console.log("cum");
          cheatCode.value = "";
          currencies.cumProgress.purchase(500);
        }

        if (cheatCode.value.includes("cost")) {
          console.log("cost");
          cheatCode.value = "";
          cheatsActive.value.cost = !cheatsActive.value.cost;
        }

        if (cheatCode.value.includes("session")) {
          console.log("session");
          cheatCode.value = "";
          cheatsActive.value.session = !cheatsActive.value.session;
        }
      };

      document.addEventListener("keypress", cheatsListener);
    }
  }

  return {
    cheatCode,
    cheatsActive,
    addCheatCodeEvent,
  };
});
