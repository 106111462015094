import { vModelText as _vModelText, createElementVNode as _createElementVNode, withDirectives as _withDirectives, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-873dc7be"),n=n(),_popScopeId(),n)
const _hoisted_1 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("footer", {
    class: _normalizeClass({ gayge: _ctx.cheatsActive.gayge })
  }, [
    (_ctx.cheatsActive.tickRate)
      ? (_openBlock(), _createElementBlock("label", _hoisted_1, [
          _createTextVNode(" Tick Rate (ms) "),
          _withDirectives(_createElementVNode("input", {
            class: "tick-rate",
            type: "number",
            min: "10",
            max: "100000",
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.tickRate) = $event))
          }, null, 512), [
            [_vModelText, _ctx.tickRate]
          ])
        ]))
      : _createCommentVNode("", true),
    _createTextVNode(" Made with shame and disgust by Jaygles#9462   "),
    _createElementVNode("button", {
      onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.resetGame && _ctx.resetGame(...args)))
    }, "Reset")
  ], 2))
}