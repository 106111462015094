import { defineStore } from "pinia";
import { computed, ref } from "vue";

import { Cost, Entity } from "@/store/game";
import { useUnitsStore } from "@/store/units";
import { give, tryPurchase } from "@/util";

export interface CumBuilding extends Entity {
  cost: Cost[];
  give: (amount: number) => void;
}

export type CumBuildingKey =
  | "cumMines"
  | "cumFactories"
  | "cumGardens"
  | "tenKPcs"
  | "onlycumsSubscriptions"
  | "summoningCircles"
  | "elementalBracelets"
  | "WFHSetups"
  | "genieLamps"
  | "cumGauntlets"
  | "borpaBookPros"
  | "studentLoans"
  | "twoYearsExperience"
  | "torBrowsers"
  | "streamingSetups";

export const useBuildingsStore = defineStore("buildings", () => {
  function purchase(this: CumBuilding, amount: number) {
    const purchased = tryPurchase(this.cost, amount);

    if (purchased) {
      this.give(amount);
    }
  }

  const buildings: { [key: string]: CumBuilding } = {
    cumMines: {
      name: "Cum Mine",
      infoText: "Each cum mine can accommodate ten borpa miners",
      amount: 0,
      isVisible: computed(() => true),
      cost: [
        {
          type: "currencies",
          item: "cums",
          amount: 100,
        },
      ],
      purchase,
      give: give("buildings.cumMines"),
    },
    cumFactories: {
      name: "Cum Factory",
      infoText: "Each cum factory can accommodate one hundred borpa workers",
      amount: 0,
      isVisible: computed(() => {
        const { units } = useUnitsStore();
        const { buildings } = useBuildingsStore();

        return buildings.cumFactories.amount > 0 || Boolean(units.borpaWorkers.isVisible);
      }),
      cost: [
        {
          type: "currencies",
          item: "cums",
          amount: 10000,
        },
      ],
      purchase,
      give: give("buildings.cumFactories"),
    },
    cumGardens: {
      name: "Cum Garden",
      infoText: "Each cum garden can accommodate two borpa gardeners",
      amount: 0,
      isVisible: computed(() => {
        const { units } = useUnitsStore();
        const { buildings } = useBuildingsStore();

        return buildings.cumGardens.amount > 0 || Boolean(units.borpaGardeners.isVisible);
      }),
      cost: [
        {
          type: "currencies",
          item: "cums",
          amount: 50000,
        },
      ],
      purchase,
      give: give("buildings.cumGardens"),
    },
    tenKPcs: {
      name: "10K PC",
      infoText:
        "Each 10K PC can accommodate only 1 borpa gamer, and when gamers aren't gaming, they're typing with one hand if you know what I mean",
      amount: 0,
      isVisible: computed(() => {
        const { units } = useUnitsStore();
        const { buildings } = useBuildingsStore();

        return buildings.tenKPcs.amount > 0 || Boolean(units.borpaGamers.isVisible);
      }),
      cost: [
        {
          type: "currencies",
          item: "cums",
          amount: 400000,
        },
      ],
      purchase,
      give: give("buildings.tenKPcs"),
    },
    onlycumsSubscriptions: {
      name: "Onlycums Subscription",
      infoText:
        "Each Onlycums Subscriptions can be used by 10 borpa coomers. Coomers have no longer have interest in games, they type with no hands if you know what I mean",
      amount: 0,
      isVisible: computed(() => {
        const { units } = useUnitsStore();
        const { buildings } = useBuildingsStore();

        return buildings.onlycumsSubscriptions.amount > 0 || Boolean(units.borpaCoomers.isVisible);
      }),
      cost: [
        {
          type: "currencies",
          item: "cums",
          amount: 10000000,
        },
      ],
      purchase,
      give: give("buildings.onlycumsSubscriptions"),
    },
    summoningCircles: {
      name: "Summoning Circle",
      infoText:
        "Each summoning circle has ten ritual sacri-... er meditation spots your borpa mystics can focus their energies in",
      amount: 0,
      isVisible: computed(() => {
        const { units } = useUnitsStore();
        const { buildings } = useBuildingsStore();

        return buildings.summoningCircles.amount > 0 || Boolean(units.borpaMystics.isVisible);
      }),
      cost: [
        {
          type: "currencies",
          item: "cums",
          amount: 666,
        },
        {
          type: "currencies",
          item: "cumMotes",
          amount: 30,
        },
      ],
      purchase,
      give: give("buildings.summoningCircles"),
    },
    elementalBracelets: {
      name: "Elemental Bracelets",
      infoText: "Each pair of Elemental Bracelet will allow you to subjugate two cumbo elementals",
      amount: 0,
      isVisible: computed(() => {
        const { units } = useUnitsStore();
        const { buildings } = useBuildingsStore();

        return buildings.elementalBracelets.amount > 0 || Boolean(units.cumboElementals.isVisible);
      }),
      cost: [
        {
          type: "currencies",
          item: "cums",
          amount: 666,
        },
        {
          type: "currencies",
          item: "cumMotes",
          amount: 500,
        },
        {
          type: "units",
          item: "borpaGardeners",
          amount: 500,
        },
      ],
      purchase,
      give: give("buildings.elementalBracelets"),
    },
    WFHSetups: {
      name: "WFH Setups",
      infoText: "Each WFH Setup will accommodate 1 Borpa Recruiter",
      amount: 0,
      isVisible: computed(() => {
        const { units } = useUnitsStore();
        const { buildings } = useBuildingsStore();

        return buildings.WFHSetups.amount > 0 || Boolean(units.borpaRecruiters.isVisible);
      }),
      cost: [
        {
          type: "currencies",
          item: "cumMotes",
          amount: 5000,
        },
      ],
      purchase,
      give: give("buildings.WFHSetups"),
    },
    genieLamps: {
      name: "Genie Lamps",
      infoText: "Each Genie Lamp can hold three cumbo genies",
      amount: 0,
      isVisible: computed(() => {
        const { units } = useUnitsStore();
        const { buildings } = useBuildingsStore();

        return buildings.WFHSetups.amount > 0 || Boolean(units.cumboGenies.isVisible);
      }),
      cost: [
        {
          type: "currencies",
          item: "cumMotes",
          amount: 500000,
        },
      ],
      purchase,
      give: give("buildings.genieLamps"),
    },
    cumGauntlets: {
      name: "Cum Gauntlets",
      infoText: "A ten pack of cum gauntlets, each can control one cumlord",
      amount: 0,
      isVisible: computed(() => {
        const { units } = useUnitsStore();
        const { buildings } = useBuildingsStore();

        return buildings.cumGauntlets.amount > 0 || Boolean(units.cumLords.isVisible);
      }),
      cost: [
        {
          type: "currencies",
          item: "cumMotes",
          amount: 50_000_000,
        },
      ],
      purchase,
      give: give("buildings.cumGauntlets"),
    },
    borpaBookPros: {
      name: "BorpaBook Pro",
      infoText:
        "An expensive BorpaBook Pro laptop. The Borpa Script Kiddies insist they can only work on these. At least you are able to get them to share one between five borpas",
      amount: 0,
      isVisible: computed(() => {
        const { units } = useUnitsStore();
        const { buildings } = useBuildingsStore();

        return buildings.borpaBookPros.amount > 0 || Boolean(units.borpaScriptKiddies.isVisible);
      }),
      cost: [
        {
          type: "currencies",
          item: "cumMotes",
          amount: 100_000_000,
        },
        {
          type: "currencies",
          item: "cumCoin",
          amount: 2,
        },
      ],
      purchase,
      give: give("buildings.borpaBookPros"),
    },
    studentLoans: {
      name: "15k Student Loan",
      infoText:
        "Expand your loan department. Adds capacity to offer another 15k loan to anyone who can sign their name. Allows you to add one more Borpa Bootcamp Grad to your team",
      amount: 0,
      isVisible: computed(() => {
        const { units } = useUnitsStore();
        const { buildings } = useBuildingsStore();

        return buildings.studentLoans.amount > 0 || Boolean(units.borpaBootcampGrads.isVisible);
      }),
      cost: [
        {
          type: "currencies",
          item: "cumCoin",
          amount: 1,
        },
      ],
      purchase,
      give: give("buildings.studentLoans"),
    },
    twoYearsExperience: {
      name: "Two Years' Experience",
      infoText:
        "Everyone says you should buy experiences and not things with your money. Well you're taking that advice and purchasing two years of experience for your Borpa Bootcamp Grads. Each two years of experience allows you to promote one bootcamp grad to a Borpa Senior Engineer",
      amount: 0,
      isVisible: computed(() => {
        const { units } = useUnitsStore();
        const { buildings } = useBuildingsStore();

        return (
          buildings.twoYearsExperience.amount > 0 || Boolean(units.borpaSeniorEngineers.isVisible)
        );
      }),
      cost: [
        {
          type: "currencies",
          item: "cumCoin",
          amount: 100_000,
        },
      ],
      purchase,
      give: give("buildings.twoYearsExperience"),
    },
    torBrowsers: {
      name: "Tor Browser License",
      infoText:
        "Obtain a license for a tor browser. Each browser license obtained will allow 10 Borpa Haxxors access to the Cum Web",
      amount: 0,
      isVisible: computed(() => {
        const { units } = useUnitsStore();
        const { buildings } = useBuildingsStore();

        return buildings.torBrowsers.amount > 0 || Boolean(units.borpaHaxxors.isVisible);
      }),
      cost: [
        {
          type: "currencies",
          item: "cumCoin",
          amount: 50_000_000,
        },
      ],
      purchase,
      give: give("buildings.torBrowsers"),
    },
    streamingSetups: {
      name: "Streaming Setup",
      infoText:
        "Each streaming setup includes two 10K PCs, a green screen, professional cameras and microphones, and the gear and software required to support all of that. Each streaming setup can be used by two Borpa Streamers, one taking the day shift and one taking the night",
      amount: 0,
      isVisible: computed(() => {
        const { units } = useUnitsStore();
        const { buildings } = useBuildingsStore();

        return buildings.streamingSetups.amount > 0 || Boolean(units.borpaStreamers.isVisible);
      }),
      cost: [
        {
          type: "currencies",
          item: "cumCoin",
          amount: 100_000_000,
        },
      ],
      purchase,
      give: give("buildings.streamingSetups"),
    },
  };

  return { buildings: ref(buildings) };
});
