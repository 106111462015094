import { defineStore } from "pinia";
import { computed, ComputedRef, ref } from "vue";

import { tryPurchase } from "@/util";
import { Cost, useGameStore } from "@/store/game";
import { useCurrenciesStore } from "@/store/currencies";
import { useUnitsStore } from "@/store/units";

export type UpgradeTier = {
  name: string;
  infoText: string;
  isVisible: ComputedRef<boolean>;
  cost: Cost[];
  onTick?(tick: number): void;
};

type CumUpgrade = {
  amount: number;
  active?: boolean;
  purchase(): void;
  tiers: UpgradeTier[];
};

export const useUpgradesStore = defineStore("upgrades", () => {
  function purchase(this: CumUpgrade) {
    if (this.amount >= this.tiers.length) return;
    const purchased = tryPurchase(this.tiers[this.amount].cost, 1);

    if (purchased) {
      this.amount += 1;
    }
  }

  const upgrades: { [key: string]: CumUpgrade } = {
    // Adds an onTick that adds cumProgress, higher the tier the quicker the rate
    // 10 Cums
    // 1_500 Cums
    // 20_000 Cums
    // 1_000_000 Cums
    // 50_000_000 Cums
    autoStroker: {
      amount: 0,
      purchase,
      tiers: [
        {
          name: "Auto Stroker 1000",
          infoText: "The Auto Stroker 1000 will automatically stroke it once per second",
          isVisible: computed(() => {
            const { upgrades } = useUpgradesStore();

            return upgrades.autoStroker.amount === 0;
          }),
          cost: [
            {
              type: "currencies",
              item: "cums",
              amount: 10,
            },
          ],
          onTick(tick) {
            const { upgrades } = useUpgradesStore();
            if (upgrades.autoStroker.amount < 1) return;
            const { tickRateFactor } = useGameStore();

            let rate = 10;
            if (upgrades.autoStroker.amount === 2) rate = 5;
            if (upgrades.autoStroker.amount === 3) rate = 3;
            if (upgrades.autoStroker.amount === 4) rate = 2;
            if (upgrades.autoStroker.amount === 5) rate = 1;

            if (tick % rate === 0) {
              const { currencies } = useCurrenciesStore();
              const base = 8 + Math.random() * 2;
              const cumProgressGain = base * tickRateFactor;

              currencies.cumProgress.purchase(cumProgressGain);
            }
          },
        },
        {
          name: "Auto Stroker 3000",
          infoText: "The Auto Stroker 3000 will automatically stroke it twice per second",
          isVisible: computed(() => {
            const { currencies } = useCurrenciesStore();
            const { upgrades } = useUpgradesStore();

            return upgrades.autoStroker.amount === 1 && currencies.cums.maxObtained > 1_250;
          }),
          cost: [
            {
              type: "currencies",
              item: "cums",
              amount: 1_500,
            },
          ],
        },
        {
          name: "Auto Stroker 4000",
          infoText: "The Auto Stroker 4000 will automatically stroke it three times per second",
          isVisible: computed(() => {
            const { currencies } = useCurrenciesStore();
            const { upgrades } = useUpgradesStore();

            return upgrades.autoStroker.amount === 2 && currencies.cums.maxObtained > 18_000;
          }),
          cost: [
            {
              type: "currencies",
              item: "cums",
              amount: 20_000,
            },
          ],
        },
        {
          name: "Giga Auto Stroker 9000",
          infoText: "The Giga Auto Stroker 9000 will automatically stroke it five times per second",
          isVisible: computed(() => {
            const { currencies } = useCurrenciesStore();
            const { upgrades } = useUpgradesStore();

            return upgrades.autoStroker.amount === 3 && currencies.cums.maxObtained > 800_000;
          }),
          cost: [
            {
              type: "currencies",
              item: "cums",
              amount: 1_000_000,
            },
          ],
        },
        {
          name: "Omega Giga Auto Stroker Max Of Hell",
          infoText:
            "The Omega Giga Auto Stroker Max will automatically stroke it ten times per second",
          isVisible: computed(() => {
            const { currencies } = useCurrenciesStore();
            const { upgrades } = useUpgradesStore();

            return upgrades.autoStroker.amount === 4 && currencies.cums.maxObtained > 45_000_000;
          }),
          cost: [
            {
              type: "currencies",
              item: "cums",
              amount: 50_000_000,
            },
          ],
        },
      ],
    },
    // Doubles cums per upgrade tier
    // 50 Cums
    // 200 Cums
    // 500 Cums
    // 5_000 Cums
    // 25_000 Cums
    // 50_000 Cums
    // 100_000 Cums
    // 200_000 Cums
    // 1_000_000 Cums
    // 3_000_000 Cums
    // 5_000_000 Cums
    // 10_000_000 Cums
    // 25_000_000 Cums
    // 50_000_000 Cums
    // 100_000_000 Cums
    doubleClimax: {
      amount: 0,
      purchase,
      tiers: [
        {
          name: "Basic Ball Massage Technique",
          infoText: "Learn basic ball massaging, doubling your cums per climax",
          isVisible: computed(() => {
            const { upgrades } = useUpgradesStore();

            return upgrades.doubleClimax.amount === 0;
          }),
          cost: [
            {
              type: "currencies",
              item: "cums",
              amount: 50,
            },
          ],
        },
        {
          name: "Mind Over Matter",
          infoText:
            "You learn to concentrate insanely hard about cum, doubling your cums per climax",
          isVisible: computed(() => {
            const { currencies } = useCurrenciesStore();
            const { upgrades } = useUpgradesStore();

            return upgrades.doubleClimax.amount === 1 && currencies.cums.maxObtained >= 180;
          }),
          cost: [
            {
              type: "currencies",
              item: "cums",
              amount: 200,
            },
          ],
        },
        {
          name: "Kegels",
          infoText:
            "You do kegel exercises every day, allowing you to cum twice as much per climax",
          isVisible: computed(() => {
            const { currencies } = useCurrenciesStore();
            const { upgrades } = useUpgradesStore();

            return upgrades.doubleClimax.amount === 2 && currencies.cums.maxObtained >= 400;
          }),
          cost: [
            {
              type: "currencies",
              item: "cums",
              amount: 500,
            },
          ],
        },
        {
          name: "Advanced Ball Massage Technique",
          infoText: "Improve your ball massaging, doubling your cums per climax again",
          isVisible: computed(() => {
            const { currencies } = useCurrenciesStore();
            const { upgrades } = useUpgradesStore();

            return upgrades.doubleClimax.amount === 3 && currencies.cums.maxObtained >= 4_500;
          }),
          cost: [
            {
              type: "currencies",
              item: "cums",
              amount: 5_000,
            },
          ],
        },
        {
          name: "Eat Pineapple",
          infoText: "Eating Pineapple will double your cums per climax again",
          isVisible: computed(() => {
            const { currencies } = useCurrenciesStore();
            const { upgrades } = useUpgradesStore();

            return upgrades.doubleClimax.amount === 4 && currencies.cums.maxObtained > 23_000;
          }),
          cost: [
            {
              type: "currencies",
              item: "cums",
              amount: 25_000,
            },
          ],
        },
        {
          name: "Third Ball",
          infoText:
            "You focus all your energy into growing a third ball. With an extra ball you cum twice as much per climax!",
          isVisible: computed(() => {
            const { currencies } = useCurrenciesStore();
            const { upgrades } = useUpgradesStore();

            return upgrades.doubleClimax.amount === 5 && currencies.cums.maxObtained > 40_000;
          }),
          cost: [
            {
              type: "currencies",
              item: "cums",
              amount: 50_000,
            },
          ],
        },
        {
          name: "Butt Plug",
          infoText: "You've got to press all the buttons. Twice as much cum per climax",
          isVisible: computed(() => {
            const { currencies } = useCurrenciesStore();
            const { upgrades } = useUpgradesStore();

            return upgrades.doubleClimax.amount === 6 && currencies.cums.maxObtained > 80_000;
          }),
          cost: [
            {
              type: "currencies",
              item: "cums",
              amount: 100_000,
            },
          ],
        },
        {
          name: "Porn",
          infoText:
            "So far you've been doing this with only your hand and your imagination, some good ol' pornography might widen the flow. Double your cum again",
          isVisible: computed(() => {
            const { currencies } = useCurrenciesStore();
            const { upgrades } = useUpgradesStore();

            return upgrades.doubleClimax.amount === 7 && currencies.cums.maxObtained > 180_000;
          }),
          cost: [
            {
              type: "currencies",
              item: "cums",
              amount: 200_000,
            },
          ],
        },
        {
          name: "Sketchy Enhancement Pills",
          infoText:
            "In your search to improve libido, a borpa recommended a certain brand of pills you can get at the local gas station. Whatd'ya know, they work! Double cums per climax",
          isVisible: computed(() => {
            const { currencies } = useCurrenciesStore();
            const { upgrades } = useUpgradesStore();

            return upgrades.doubleClimax.amount === 8 && currencies.cums.maxObtained > 800_000;
          }),
          cost: [
            {
              type: "currencies",
              item: "cums",
              amount: 1_000_000,
            },
          ],
        },
        {
          name: "Scented Candles",
          infoText: "Some scented candles to get you in the mood. Double your cum again",
          isVisible: computed(() => {
            const { currencies } = useCurrenciesStore();
            const { upgrades } = useUpgradesStore();

            return upgrades.doubleClimax.amount === 9 && currencies.cums.maxObtained > 2_000_000;
          }),
          cost: [
            {
              type: "currencies",
              item: "cums",
              amount: 3_000_000,
            },
          ],
        },
        {
          name: "Giga Porn",
          infoText: "You can afford the good stuff now. Cums per climax double once more",
          isVisible: computed(() => {
            const { currencies } = useCurrenciesStore();
            const { upgrades } = useUpgradesStore();

            return upgrades.doubleClimax.amount === 10 && currencies.cums.maxObtained > 4_000_000;
          }),
          cost: [
            {
              type: "currencies",
              item: "cums",
              amount: 5_000_000,
            },
          ],
        },
        {
          name: "Drink Water",
          infoText:
            "All this cumming has made you dehydrated. You make an effort to drink more water. Cums per climax double once more",
          isVisible: computed(() => {
            const { currencies } = useCurrenciesStore();
            const { upgrades } = useUpgradesStore();

            return upgrades.doubleClimax.amount === 11 && currencies.cums.maxObtained > 8_000_000;
          }),
          cost: [
            {
              type: "currencies",
              item: "cums",
              amount: 10_000_000,
            },
          ],
        },
        {
          name: "Two Hands",
          infoText:
            "You've been working on it for a while and now feel confident to use both hands. Cums per climax double again",
          isVisible: computed(() => {
            const { currencies } = useCurrenciesStore();
            const { upgrades } = useUpgradesStore();

            return upgrades.doubleClimax.amount === 12 && currencies.cums.maxObtained > 22_000_000;
          }),
          cost: [
            {
              type: "currencies",
              item: "cums",
              amount: 25_000_000,
            },
          ],
        },
        {
          name: "Three Hands",
          infoText: "Whose hand is that? Ah fuck it get in there. Cums per climax double again",
          isVisible: computed(() => {
            const { currencies } = useCurrenciesStore();
            const { upgrades } = useUpgradesStore();

            return upgrades.doubleClimax.amount === 13 && currencies.cums.maxObtained > 45_000_000;
          }),
          cost: [
            {
              type: "currencies",
              item: "cums",
              amount: 50_000_000,
            },
          ],
        },
        {
          name: "Cum Vacuum",
          infoText:
            "A degenerate friend shared with you a trick. Use a vacuum to get every last drop of cum out. Cums per climax double again",
          isVisible: computed(() => {
            const { currencies } = useCurrenciesStore();
            const { upgrades } = useUpgradesStore();

            return upgrades.doubleClimax.amount === 14 && currencies.cums.maxObtained > 80_000_000;
          }),
          cost: [
            {
              type: "currencies",
              item: "cums",
              amount: 100_000_000,
            },
          ],
        },
      ],
    },
    // Fewer strokes per climax
    // 100 Cums
    // 1_000_000 Cums
    lotion: {
      amount: 0,
      purchase,
      tiers: [
        {
          name: "Jergens Lotion",
          infoText: "Using lotion will halve the strokes needed per climax",
          isVisible: computed(() => {
            const { currencies } = useCurrenciesStore();
            const { upgrades } = useUpgradesStore();

            return currencies.cums.maxObtained > 75 && upgrades.lotion.amount === 0;
          }),
          cost: [
            {
              type: "currencies",
              item: "cums",
              amount: 100,
            },
          ],
        },
        {
          name: "Ceravé Lotion",
          infoText:
            "You can afford the fancy lotion now, each stroke will get you 69% closer to climax. You're a two pump chump!",
          isVisible: computed(() => {
            const { currencies } = useCurrenciesStore();
            const { upgrades } = useUpgradesStore();

            return currencies.cums.maxObtained > 800_000 && upgrades.lotion.amount === 1;
          }),
          cost: [
            {
              type: "currencies",
              item: "cums",
              amount: 1_000_000,
            },
          ],
        },
      ],
    },
    // Unique events
    // 200 Cums
    littleGreenPill: {
      amount: 0,
      purchase,
      tiers: [
        {
          name: "Little Green Pill",
          infoText: "This will make your cum taste minty",
          isVisible: computed(() => {
            const { currencies } = useCurrenciesStore();
            const { upgrades } = useUpgradesStore();

            return currencies.cums.maxObtained > 100 && upgrades.littleGreenPill.amount === 0;
          }),
          cost: [
            {
              type: "currencies",
              item: "cums",
              amount: 200,
            },
          ],
        },
      ],
    },
    // 500 Cums
    // 20_000 Cums
    // 500_000 Cums
    minerUpgrade: {
      amount: 0,
      purchase,
      tiers: [
        {
          name: "Diamond Cum Pickaxes",
          infoText: "Buy diamond pickaxes for your cum miners, doubles their effectiveness",
          isVisible: computed(() => {
            const { units } = useUnitsStore();
            const { currencies } = useCurrenciesStore();
            const { upgrades } = useUpgradesStore();

            return (
              upgrades.minerUpgrade.amount === 0 &&
              units.borpaMiners.amount >= 1 &&
              currencies.cums.maxObtained > 450
            );
          }),
          cost: [
            {
              type: "currencies",
              item: "cums",
              amount: 500,
            },
          ],
        },
        {
          name: "Miners Use Both Arms",
          infoText:
            "Teach your borpa miners to wield and use a pickaxe per hand, doubling their effectiveness",
          isVisible: computed(() => {
            const { units } = useUnitsStore();
            const { currencies } = useCurrenciesStore();
            const { upgrades } = useUpgradesStore();

            return (
              upgrades.minerUpgrade.amount === 1 &&
              units.borpaMiners.amount >= 1 &&
              currencies.cums.maxObtained > 18_000
            );
          }),
          cost: [
            {
              type: "currencies",
              item: "cums",
              amount: 20_000,
            },
          ],
        },
        {
          name: "Miners Use Their Cock Too",
          infoText:
            "Teach your borpa miners to wield and use a pickaxe with their cock? Doubling their effectiveness",
          isVisible: computed(() => {
            const { units } = useUnitsStore();
            const { currencies } = useCurrenciesStore();
            const { upgrades } = useUpgradesStore();

            return (
              upgrades.minerUpgrade.amount === 2 &&
              units.borpaMiners.amount >= 1 &&
              currencies.cums.maxObtained > 450_000
            );
          }),
          cost: [
            {
              type: "currencies",
              item: "cums",
              amount: 500_000,
            },
          ],
        },
      ],
    },
    // 1_000 Cums
    // 1_000_000 Cums
    cumJar: {
      amount: 0,
      purchase,
      tiers: [
        {
          name: "Upgrade Cum Jar - Gallon Jug",
          infoText: "This will increase the maximum amount of cums you can hold to 1,000,000",
          isVisible: computed(() => {
            const { currencies } = useCurrenciesStore();
            const { upgrades } = useUpgradesStore();

            return currencies.cums.maxObtained >= 900 && upgrades.cumJar.amount === 0;
          }),
          cost: [
            {
              type: "currencies",
              item: "cums",
              amount: 1_000,
            },
          ],
        },
        {
          name: "Upgrade Cum Jar - Giga Vat",
          infoText: "This will increase the maximum amount of cums you can hold to 1,000,000,000",
          isVisible: computed(() => {
            const { currencies } = useCurrenciesStore();
            const { upgrades } = useUpgradesStore();

            return currencies.cums.maxObtained >= 900_000 && upgrades.cumJar.amount === 1;
          }),
          cost: [
            {
              type: "currencies",
              item: "cums",
              amount: 1_000_000,
            },
          ],
        },
      ],
    },
    // 25_000 Cums
    // 500_000 Cums
    // 5_000_000 Cums
    workerUpgrade: {
      amount: 0,
      purchase,
      tiers: [
        {
          name: "Cocaine for Borpa Workers",
          infoText:
            "You buy a shit ton of cocaine which will double the output of your borpa workers",
          isVisible: computed(() => {
            const { currencies } = useCurrenciesStore();
            const { upgrades } = useUpgradesStore();

            return currencies.cums.maxObtained >= 22500 && upgrades.workerUpgrade.amount === 0;
          }),
          cost: [
            {
              type: "currencies",
              item: "cums",
              amount: 25_000,
            },
          ],
        },
        {
          name: "Crush The Borpa Worker Union",
          infoText:
            "You hire the sleaziest law firm around to crush the Borpa Worker's union. The reduced cost of labor doubles your cum profits per worker",
          isVisible: computed(() => {
            const { currencies } = useCurrenciesStore();
            const { upgrades } = useUpgradesStore();

            return currencies.cums.maxObtained >= 450_000 && upgrades.workerUpgrade.amount === 1;
          }),
          cost: [
            {
              type: "currencies",
              item: "cums",
              amount: 500_000,
            },
          ],
        },
        {
          name: "Company Towns",
          infoText:
            "You construct company towns for you to better exploi... house your borpa workers, doubling your profits per worker",
          isVisible: computed(() => {
            const { currencies } = useCurrenciesStore();
            const { upgrades } = useUpgradesStore();

            return currencies.cums.maxObtained >= 4_500_000 && upgrades.workerUpgrade.amount === 2;
          }),
          cost: [
            {
              type: "currencies",
              item: "cums",
              amount: 5_000_000,
            },
          ],
        },
      ],
    },
    // 50_000 Cums
    // 5_000_000 Cums
    // 10 Cum Coins
    multipleBorpaButtons: {
      amount: 0,
      purchase,
      tiers: [
        {
          name: "Borpa Resources",
          infoText:
            "You are a large operation now. Get a Borpa Resources department to better manage your workforce. Unlocks the ability to hire 10 Borpas at a time",
          isVisible: computed(() => {
            const { currencies } = useCurrenciesStore();
            const { upgrades } = useUpgradesStore();

            return (
              currencies.cums.maxObtained >= 45000 && upgrades.multipleBorpaButtons.amount === 0
            );
          }),
          cost: [
            {
              type: "currencies",
              item: "cums",
              amount: 50_000,
            },
          ],
        },
        {
          name: "Borpas International",
          infoText:
            "Your operations are going international. You'll need headquarters in all the major cities, and the Borpa Resources departments alongside them. Unlocks the ability to hire 100 Borpas at a time",
          isVisible: computed(() => {
            const { currencies } = useCurrenciesStore();
            const { upgrades } = useUpgradesStore();

            return (
              currencies.cums.maxObtained >= 4_500_000 && upgrades.multipleBorpaButtons.amount === 1
            );
          }),
          cost: [
            {
              type: "currencies",
              item: "cums",
              amount: 5_000_000,
            },
          ],
        },
        {
          name: "Borpas R Us",
          infoText:
            "Your operations have grown to such an extent, it's time to call in the big guns.",
          isVisible: computed(() => {
            const { currencies } = useCurrenciesStore();
            const { upgrades } = useUpgradesStore();

            return (
              currencies.cumCoin.maxObtained >= 5 && upgrades.multipleBorpaButtons.amount === 2
            );
          }),
          cost: [
            {
              type: "currencies",
              item: "cumCoin",
              amount: 10,
            },
          ],
        },
      ],
    },
    // 50_000 Cums
    // 5_000_000 Cums
    // 100 Cum Coins
    multipleBuildingButtons: {
      amount: 0,
      purchase,
      tiers: [
        {
          name: "Borpa Contractors",
          infoText:
            "You are a large operation now. Vet and recruit some Borpa Contractors to better manage your construction. Unlocks the ability to build 10 buildings at a time",
          isVisible: computed(() => {
            const { currencies } = useCurrenciesStore();
            const { upgrades } = useUpgradesStore();

            return (
              currencies.cums.maxObtained >= 45_000 && upgrades.multipleBuildingButtons.amount === 0
            );
          }),
          cost: [
            {
              type: "currencies",
              item: "cums",
              amount: 50_000,
            },
          ],
        },
        {
          name: "Borpa Construction Llc",
          infoText:
            "Things are getting serious. Time for some horizontal integration. Purchase your own construction company to unlock building 100 buildings at a time.",
          isVisible: computed(() => {
            const { currencies } = useCurrenciesStore();
            const { upgrades } = useUpgradesStore();

            return (
              currencies.cums.maxObtained >= 4_500_000 &&
              upgrades.multipleBuildingButtons.amount === 1
            );
          }),
          cost: [
            {
              type: "currencies",
              item: "cums",
              amount: 5_000_000,
            },
          ],
        },
        {
          name: "Borpa Terraforming",
          infoText:
            "You can build fast enough, but it's getting hard to find places to build. You look towards ways to create more buildable area. On other planets perhaps?",
          isVisible: computed(() => {
            const { currencies } = useCurrenciesStore();
            const { upgrades } = useUpgradesStore();

            return (
              currencies.cumCoin.maxObtained >= 90 && upgrades.multipleBuildingButtons.amount === 2
            );
          }),
          cost: [
            {
              type: "currencies",
              item: "cumCoin",
              amount: 100,
            },
          ],
        },
      ],
    },
    // 1_000_001 Cums
    // 15_000_001 Cums
    // 250_000_001 Cums
    gardenUpgrade: {
      amount: 0,
      purchase,
      tiers: [
        {
          name: "Extra Gardener Arms",
          infoText:
            "You carefully apply some of your more toxic and green cum to your gardeners, they each grow two extra arms letting them garden twice as quickly",
          isVisible: computed(() => {
            const { currencies } = useCurrenciesStore();
            const { upgrades } = useUpgradesStore();

            return currencies.cums.maxObtained >= 900_001 && upgrades.gardenUpgrade.amount === 0;
          }),
          cost: [
            {
              type: "currencies",
              item: "cums",
              amount: 1_000_001,
            },
          ],
        },
        {
          name: "Giga Fertilizer",
          cost: [
            {
              type: "currencies",
              item: "cums",
              amount: 15_000_001,
            },
          ],
          infoText:
            "Advances in science and technology has produced giga fertilizer. Purchasing this will double your cum garden output",
          isVisible: computed(() => {
            const { currencies } = useCurrenciesStore();
            const { upgrades } = useUpgradesStore();

            return currencies.cums.maxObtained >= 14_000_001 && upgrades.gardenUpgrade.amount === 1;
          }),
        },
        {
          name: "Global Warming",
          cost: [
            {
              type: "currencies",
              item: "cums",
              amount: 250_000_001,
            },
          ],
          infoText:
            "Growing the size of your cumperation at any cost has caused global warming, mostly from the output of your cum factories. Running the numbers you figure the higher temperature will increase the output of your cum gardens by roughly double. Climate change FTW!!",
          isVisible: computed(() => {
            const { currencies } = useCurrenciesStore();
            const { upgrades } = useUpgradesStore();

            return (
              currencies.cums.maxObtained >= 225_000_001 && upgrades.gardenUpgrade.amount === 2
            );
          }),
        },
      ],
    },
    // 4_000_000 Cums
    // 60_000_000 Cums
    // 100_000_000 Cums
    gamerUpgrade: {
      amount: 0,
      purchase,
      tiers: [
        {
          name: "4090s for your 10K PCs",
          infoText:
            "Wait, you spent 10k per PC and they don't have 4090s? Did you save your receipts...? (Borpa Gamers are now twice as effective with 4090s)",
          isVisible: computed(() => {
            const { currencies } = useCurrenciesStore();
            const { upgrades } = useUpgradesStore();

            return currencies.cums.maxObtained >= 3_800_000 && upgrades.gamerUpgrade.amount === 0;
          }),
          cost: [
            {
              type: "currencies",
              item: "cums",
              amount: 4_000_000,
            },
          ],
        },
        {
          name: "No Mo Homework",
          infoText:
            'You pay "tutors" for your borpa gamers to "help" them with their homework. The increase in free time allows them to double their effectiveness',
          isVisible: computed(() => {
            const { currencies } = useCurrenciesStore();
            const { upgrades } = useUpgradesStore();

            return currencies.cums.maxObtained >= 50_000_000 && upgrades.gamerUpgrade.amount === 1;
          }),
          cost: [
            {
              type: "currencies",
              item: "cums",
              amount: 60_000_000,
            },
          ],
        },
        {
          name: "Coaching",
          infoText: "You hire gaming coaches for your borpa gamers. Their MMR is doubled",
          isVisible: computed(() => {
            const { currencies } = useCurrenciesStore();
            const { upgrades } = useUpgradesStore();

            return currencies.cums.maxObtained >= 90_000_000 && upgrades.gamerUpgrade.amount === 2;
          }),
          cost: [
            {
              type: "currencies",
              item: "cums",
              amount: 100_000_000,
            },
          ],
        },
      ],
    },
    // 50_000_000 Cums
    // 75_000_000 Cums
    // 100_000_000 Cums
    coomerUpgrade: {
      amount: 0,
      purchase,
      tiers: [
        {
          name: "Ultra premium subscriptions on Onlycums",
          infoText:
            "You plunk down the cums needed to upgrade to the ultra premium onlycums subscriptions. The coom will flow. Borpa Coomers are now twice as effective",
          isVisible: computed(() => {
            const { currencies } = useCurrenciesStore();
            const { upgrades } = useUpgradesStore();

            return currencies.cums.maxObtained >= 47_500_000 && upgrades.coomerUpgrade.amount === 0;
          }),
          cost: [
            {
              type: "currencies",
              item: "cums",
              amount: 50_000_000,
            },
          ],
        },
        {
          name: "Pass On Your Ancient Techniques",
          infoText:
            "You spend the time passing on your ancient techniques to your borpa coomers. Only a few get it, but overall you double the effectiveness of your coomers",
          isVisible: computed(() => {
            const { currencies } = useCurrenciesStore();
            const { upgrades } = useUpgradesStore();

            return currencies.cums.maxObtained >= 70_000_000 && upgrades.coomerUpgrade.amount === 1;
          }),
          cost: [
            {
              type: "currencies",
              item: "cums",
              amount: 75_000_000,
            },
          ],
        },
        {
          name: "Coomer Teamwork",
          infoText: "You teach the borpa coomers teamwork, doubling their effectiveness",
          isVisible: computed(() => {
            const { currencies } = useCurrenciesStore();
            const { upgrades } = useUpgradesStore();

            return currencies.cums.maxObtained >= 90_000_000 && upgrades.coomerUpgrade.amount === 2;
          }),
          cost: [
            {
              type: "currencies",
              item: "cums",
              amount: 100_000_000,
            },
          ],
        },
      ],
    },
    // 1 Cum Mote, 1_000_000_000 Cums, 1_000 each unit
    autoCumdense: {
      amount: 0,
      active: false,
      purchase,
      tiers: [
        {
          name: "Auto Cumdense",
          infoText:
            "You've never seen anything like it, a mote of pure cum. It gives off a powerful aura. With some meditation you are confident you can harness it's power... at a cost. Absorb the cum mote and gain the ability to automatically condense your cums into cum motes when you have enough",
          isVisible: computed(() => {
            const { currencies } = useCurrenciesStore();
            const { upgrades } = useUpgradesStore();

            return currencies.cumMotes.maxObtained >= 1 && upgrades.autoCumdense.amount === 0;
          }),
          cost: [
            {
              type: "units",
              item: "borpaMiners",
              amount: 1_000,
            },
            {
              type: "units",
              item: "borpaWorkers",
              amount: 1_000,
            },
            {
              type: "units",
              item: "borpaGardeners",
              amount: 1_000,
            },
            {
              type: "units",
              item: "borpaGamers",
              amount: 1_000,
            },
            {
              type: "units",
              item: "borpaCoomers",
              amount: 1_000,
            },
            {
              type: "currencies",
              item: "cums",
              amount: 1_000_000_000,
            },
            {
              type: "currencies",
              item: "cumMotes",
              amount: 1,
            },
          ],
        },
      ],
    },
    // 20 Cum Motes, 10_000 each unit
    // 1_000 Cum Motes, 10_000 each unit
    // 1_000_000 Cum Motes, 10_000 each unit
    cumMoteContainment: {
      amount: 0,
      purchase,
      tiers: [
        {
          name: "Mystical Cum Mote Containment",
          infoText:
            "Cum motes are a different beast entirely. You'll need to research cum mote containment at your local junior college to hold more than ten at a time",
          isVisible: computed(() => {
            const { currencies } = useCurrenciesStore();
            const { upgrades } = useUpgradesStore();

            return currencies.cumMotes.maxObtained >= 8 && upgrades.cumMoteContainment.amount === 0;
          }),
          cost: [
            {
              type: "units",
              item: "borpaMiners",
              amount: 10_000,
            },
            {
              type: "units",
              item: "borpaWorkers",
              amount: 10_000,
            },
            {
              type: "units",
              item: "borpaGardeners",
              amount: 10_000,
            },
            {
              type: "units",
              item: "borpaGamers",
              amount: 10_000,
            },
            {
              type: "units",
              item: "borpaCoomers",
              amount: 10_000,
            },
            {
              type: "currencies",
              item: "cumMotes",
              amount: 20,
            },
          ],
        },
        {
          name: "Pocket Dimension Cum Mote Containment",
          infoText: "Research pocket dimensions powerful enough to contain cum motes",
          isVisible: computed(() => {
            const { currencies } = useCurrenciesStore();
            const { upgrades } = useUpgradesStore();

            return (
              currencies.cumMotes.maxObtained >= 800 && upgrades.cumMoteContainment.amount === 1
            );
          }),
          cost: [
            {
              type: "units",
              item: "borpaMiners",
              amount: 10_000,
            },
            {
              type: "units",
              item: "borpaWorkers",
              amount: 10_000,
            },
            {
              type: "units",
              item: "borpaGardeners",
              amount: 10_000,
            },
            {
              type: "units",
              item: "borpaGamers",
              amount: 10_000,
            },
            {
              type: "units",
              item: "borpaCoomers",
              amount: 10_000,
            },
            {
              type: "currencies",
              item: "cumMotes",
              amount: 1_000,
            },
          ],
        },
        {
          name: "Simulated Black Hole Cum Mote Containment",
          infoText:
            "This is PHD level stuff! You think you can harness the power of simulated black holes to contain insane numbers of cum motes!",
          isVisible: computed(() => {
            const { currencies } = useCurrenciesStore();
            const { upgrades } = useUpgradesStore();

            return (
              currencies.cumMotes.maxObtained >= 800_000 && upgrades.cumMoteContainment.amount === 2
            );
          }),
          cost: [
            {
              type: "units",
              item: "borpaMiners",
              amount: 10_000,
            },
            {
              type: "units",
              item: "borpaWorkers",
              amount: 10_000,
            },
            {
              type: "units",
              item: "borpaGardeners",
              amount: 10_000,
            },
            {
              type: "units",
              item: "borpaGamers",
              amount: 10_000,
            },
            {
              type: "units",
              item: "borpaCoomers",
              amount: 10_000,
            },
            {
              type: "currencies",
              item: "cumMotes",
              amount: 1_000_000,
            },
          ],
        },
      ],
    },
    // 666 Cums, 100 Cum Motes
    // 666 Cums, 10_000 Cum Motes
    // 69_420 Cums, 1_000_000 Cum Motes
    mysticUpgrade: {
      amount: 0,
      purchase,
      tiers: [
        {
          name: "Silly Hats For Your Mystics",
          infoText:
            "Everyone knows silly hats help their wearers more appropriately focus their energies. Doubles effectiveness of Borpa Mystics",
          isVisible: computed(() => {
            const { currencies } = useCurrenciesStore();
            const { upgrades } = useUpgradesStore();

            return currencies.cumMotes.maxObtained >= 80 && upgrades.mysticUpgrade.amount === 0;
          }),
          cost: [
            {
              type: "currencies",
              item: "cums",
              amount: 666,
            },
            {
              type: "currencies",
              item: "cumMotes",
              amount: 100,
            },
          ],
        },
        {
          name: "JO Crystals For Your Mystics",
          infoText:
            "Custom made moissanite crystals set in silver wire on a loop of leather. When two or more wearers get together and focus their energies in a certain way, they glow. Doubles the effectiveness of your Borpa Mystics",
          isVisible: computed(() => {
            const { currencies } = useCurrenciesStore();
            const { upgrades } = useUpgradesStore();

            return currencies.cumMotes.maxObtained >= 8_000 && upgrades.mysticUpgrade.amount === 1;
          }),
          cost: [
            {
              type: "currencies",
              item: "cums",
              amount: 666,
            },
            {
              type: "currencies",
              item: "cumMotes",
              amount: 10_000,
            },
          ],
        },
        {
          name: "Cumrinomicon",
          infoText:
            "You make a deal with certain powerful entities to supply your Borpa Mystics with Cumrinomicons. Using these will double their effectiveness",
          isVisible: computed(() => {
            const { currencies } = useCurrenciesStore();
            const { upgrades } = useUpgradesStore();

            return (
              currencies.cumMotes.maxObtained >= 800_000 && upgrades.mysticUpgrade.amount === 2
            );
          }),
          cost: [
            {
              type: "currencies",
              item: "cums",
              amount: 69_420,
            },
            {
              type: "currencies",
              item: "cumMotes",
              amount: 1_000_000,
            },
          ],
        },
      ],
    },
    // 666 Cums, 10_000 Cum Motes
    // 666 Cums, 1_000_000 Cum Motes
    // 69_420 Cums, 2_000_000 Cum Motes
    elementalUpgrade: {
      amount: 0,
      purchase,
      tiers: [
        {
          name: "JO Bracelet Jewels",
          infoText: "Put JO jewels in your elemental bracelets. Doubles their effectiveness",
          isVisible: computed(() => {
            const { currencies } = useCurrenciesStore();
            const { upgrades } = useUpgradesStore();

            return (
              currencies.cumMotes.maxObtained >= 8_000 && upgrades.elementalUpgrade.amount === 0
            );
          }),
          cost: [
            {
              type: "currencies",
              item: "cums",
              amount: 666,
            },
            {
              type: "currencies",
              item: "cumMotes",
              amount: 10_000,
            },
          ],
        },
        {
          name: "Advanced cumbo subjugation",
          infoText:
            "You visit your local BDSM store. They have an entire section for cumbo elementals! You stock up on a variety of implements you are sure will improve your hold over those cumbos. Doubles effectiveness of your cumbo elementals",
          isVisible: computed(() => {
            const { currencies } = useCurrenciesStore();
            const { upgrades } = useUpgradesStore();

            return (
              currencies.cumMotes.maxObtained >= 800_000 && upgrades.elementalUpgrade.amount === 1
            );
          }),
          cost: [
            {
              type: "currencies",
              item: "cums",
              amount: 666,
            },
            {
              type: "currencies",
              item: "cumMotes",
              amount: 1_000_000,
            },
          ],
        },
        {
          name: "Stop fucking it up",
          infoText:
            "You were fucking it up this entire time. The cumbo elemental bracelets were on backwards! FUCK! Now that they are on the right way, the flow is obviously strengthened. Doubles cumbo elemental effectiveness",
          isVisible: computed(() => {
            const { currencies } = useCurrenciesStore();
            const { upgrades } = useUpgradesStore();

            return (
              currencies.cumMotes.maxObtained >= 1_800_000 && upgrades.elementalUpgrade.amount === 2
            );
          }),
          cost: [
            {
              type: "currencies",
              item: "cums",
              amount: 69_420,
            },
            {
              type: "currencies",
              item: "cumMotes",
              amount: 2_000_000,
            },
          ],
        },
      ],
    },
    // 50_000 Cum Motes
    // 666 Cumbo Elementals
    // 1_000_000 Borpa Workers
    recruiterUpgrade: {
      amount: 0,
      purchase,
      tiers: [
        {
          name: "Spam Emails",
          infoText:
            "Sign up to a spam email SAAS to double the effectiveness of your Borpa Recruiters",
          isVisible: computed(() => {
            const { currencies } = useCurrenciesStore();
            const { upgrades } = useUpgradesStore();

            return (
              currencies.cumMotes.maxObtained >= 45_000 && upgrades.recruiterUpgrade.amount === 0
            );
          }),
          cost: [
            {
              type: "currencies",
              item: "cumMotes",
              amount: 50_000,
            },
          ],
        },
        {
          name: "Dark Ritual",
          infoText:
            "Sacrifice 666 Cumbo Elementals to give your borpa recruiters a dark edge in their recruitment. Doubles their effectiveness",
          isVisible: computed(() => {
            const { currencies } = useCurrenciesStore();
            const { upgrades } = useUpgradesStore();

            return (
              currencies.cumMotes.maxObtained >= 800_000 && upgrades.recruiterUpgrade.amount === 1
            );
          }),
          cost: [
            {
              type: "units",
              item: "cumboElementals",
              amount: 666,
            },
          ],
        },
        {
          name: "Protein Shakes",
          infoText:
            "Your Borpa Recruiters are complaining about their monthly snack stipend. Give them some snacks they'll never forget. Better nutrition doubles their effectiveness",
          isVisible: computed(() => {
            const { currencies } = useCurrenciesStore();
            const { units } = useUnitsStore();
            const { upgrades } = useUpgradesStore();

            return (
              currencies.cumMotes.maxObtained >= 1 &&
              upgrades.recruiterUpgrade.amount === 2 &&
              units.borpaWorkers.amount >= 900_000
            );
          }),
          cost: [
            {
              type: "units",
              item: "borpaWorkers",
              amount: 1_000_000,
            },
          ],
        },
      ],
    },
    // 5_000_000 Cum Motes
    // 250_000_000 Cum Motes
    // 500_000_000 Cum Motes 1000 Cumbo Elementals,
    genieUpgrade: {
      amount: 0,
      purchase,
      tiers: [
        {
          name: "Brass Polish",
          infoText:
            "Shine up your genie lamps, strengthening your cumbo genies' tether to this dimension. Doubles their effectiveness",
          isVisible: computed(() => {
            const { currencies } = useCurrenciesStore();
            const { upgrades } = useUpgradesStore();

            return (
              currencies.cumMotes.maxObtained >= 45_000_000 && upgrades.genieUpgrade.amount === 0
            );
          }),
          cost: [
            {
              type: "currencies",
              item: "cumMotes",
              amount: 5_000_000,
            },
          ],
        },
        {
          name: "Fashionable Cock Rings",
          infoText:
            "Purchase a variety of fashionable cock rings for your cumbo genies. They aren't magical in any way, but will boost their confidence, allowing them to be twice as effective",
          isVisible: computed(() => {
            const { currencies } = useCurrenciesStore();
            const { upgrades } = useUpgradesStore();

            return (
              currencies.cumMotes.maxObtained >= 225_000_000 && upgrades.genieUpgrade.amount === 1
            );
          }),
          cost: [
            {
              type: "currencies",
              item: "cumMotes",
              amount: 250_000_000,
            },
          ],
        },
        {
          name: "Parrot Side Kicks",
          infoText:
            "Obtain parrots to be side kicks for your cumbo genies. Some comic relief will help make a long day of cum conjuring go by easily. Doubles cumbo genie effectiveness",
          isVisible: computed(() => {
            const { currencies } = useCurrenciesStore();
            const { units } = useUnitsStore();
            const { upgrades } = useUpgradesStore();

            return (
              currencies.cumMotes.maxObtained >= 1 &&
              upgrades.genieUpgrade.amount === 2 &&
              units.borpaWorkers.amount >= 900_000
            );
          }),
          cost: [
            {
              type: "currencies",
              item: "cumMotes",
              amount: 500_000_000,
            },
            {
              type: "units",
              item: "cumboElementals",
              amount: 1_000,
            },
          ],
        },
      ],
    },
    // 500_000_000 Cum Motes
    // 750_000_000 Cum Motes 10_000 Cumbo Genies
    // 1_000_000_000 Cum Motes 10_000 Cumlords
    cumLordUpgrade: {
      amount: 0,
      purchase,
      tiers: [
        {
          name: "Cum Stones For Cum Gauntlets",
          infoText:
            "Place powerful cum stones into your cum gauntlets. Doubling your cumlord effectiveness",
          isVisible: computed(() => {
            const { currencies } = useCurrenciesStore();
            const { upgrades } = useUpgradesStore();

            return (
              currencies.cumMotes.maxObtained >= 450_000_000 && upgrades.cumLordUpgrade.amount === 0
            );
          }),
          cost: [
            {
              type: "currencies",
              item: "cumMotes",
              amount: 500_000_000,
            },
          ],
        },
        {
          name: "Genie Wishes For Cumlords",
          infoText:
            "Have your Cumbo Genies use some of their wishing power to increase the cum mote producing power of your Cumlord fiefs, doubling their effectiveness",
          isVisible: computed(() => {
            const { currencies } = useCurrenciesStore();
            const { upgrades } = useUpgradesStore();

            return (
              currencies.cumMotes.maxObtained >= 700_000_000 && upgrades.cumLordUpgrade.amount === 1
            );
          }),
          cost: [
            {
              type: "currencies",
              item: "cumMotes",
              amount: 750_000_000,
            },
            {
              type: "units",
              item: "cumboGenies",
              amount: 10_000,
            },
          ],
        },
        {
          name: "Evil Pact",
          infoText:
            "Form an evil pact with unnamed powerful deities. The enhanced power will allow you to double your Cumlord's effectiveness",
          isVisible: computed(() => {
            const { currencies } = useCurrenciesStore();
            const { upgrades } = useUpgradesStore();

            return (
              currencies.cumMotes.maxObtained >= 950_000_000 && upgrades.cumLordUpgrade.amount === 2
            );
          }),
          cost: [
            {
              type: "currencies",
              item: "cumMotes",
              amount: 1_000_000_000,
            },
            {
              type: "units",
              item: "cumLords",
              amount: 10_000,
            },
          ],
        },
      ],
    },
    // 500_000_000 Cum Motes, 5 Cum Coin
    // 1_000 Cum Coin, 100 Cum Lords
    // 1_000_000 Cum Coin, 100 each Cum Coin unit
    cumCoinWallet: {
      amount: 0,
      purchase,
      tiers: [
        {
          name: "External HDD Cum Coin Wallet",
          infoText:
            "With 5 Cum Coin, you can finally afford to buy an external HDD, big enough to hold up to 1,000 Cum Coin!",
          isVisible: computed(() => {
            const { currencies } = useCurrenciesStore();
            const { upgrades } = useUpgradesStore();

            return currencies.cumCoin.maxObtained >= 4 && upgrades.cumCoinWallet.amount === 0;
          }),
          cost: [
            {
              type: "currencies",
              item: "cumMotes",
              amount: 500_000_000,
            },
            {
              type: "currencies",
              item: "cumCoin",
              amount: 5,
            },
          ],
        },
        {
          name: "NAS Cum Coin Wallet",
          infoText:
            "A NAS equipped with a dozen HDD will allow you to hold up to 1,000,000 Cum Coin!",
          isVisible: computed(() => {
            const { currencies } = useCurrenciesStore();
            const { upgrades } = useUpgradesStore();

            return currencies.cumCoin.maxObtained >= 800 && upgrades.cumCoinWallet.amount === 1;
          }),
          cost: [
            {
              type: "units",
              item: "cumLords",
              amount: 100,
            },
            {
              type: "currencies",
              item: "cumCoin",
              amount: 1_000,
            },
          ],
        },
        {
          name: "A dedicated data center for your Cum Coin wallet",
          infoText:
            "Things are getting serious. To take the next step, you need to buy your own data center to hold up to 1,000,000,000 Cum Coin!",
          isVisible: computed(() => {
            const { currencies } = useCurrenciesStore();
            const { upgrades } = useUpgradesStore();

            return currencies.cumCoin.maxObtained >= 800_000 && upgrades.cumCoinWallet.amount === 2;
          }),
          cost: [
            {
              type: "currencies",
              item: "cumCoin",
              amount: 1_000_000,
            },
          ],
        },
      ],
    },
    // 1_000 Cum Coin
    // 500_000_000 Cum Motes 1_000_000 Cum Coin
    // 45_000_000 Cum Coin
    borpaScriptKiddiesUpgrade: {
      amount: 0,
      purchase,
      tiers: [
        {
          name: "Coffees for your script kiddies",
          infoText:
            "They take their coffee black. Pick up some ground light roast from Trader Joe's. Doubles Borpa Script Kiddie effectiveness",
          isVisible: computed(() => {
            const { currencies } = useCurrenciesStore();
            const { upgrades } = useUpgradesStore();

            return (
              currencies.cumCoin.maxObtained >= 800 &&
              upgrades.borpaScriptKiddiesUpgrade.amount === 0
            );
          }),
          cost: [
            {
              type: "currencies",
              item: "cumCoin",
              amount: 1_000,
            },
          ],
        },
        {
          name: "BorpaPods Pro",
          infoText:
            "Your Script Kiddies have been complaining about the open office layout. Instead of capitulating to their demands, buy them some BorpaPods Pro to help them deal with the noise. Doubles their effectiveness",
          isVisible: computed(() => {
            const { currencies } = useCurrenciesStore();
            const { upgrades } = useUpgradesStore();

            return (
              currencies.cumCoin.maxObtained >= 800_000 &&
              upgrades.borpaScriptKiddiesUpgrade.amount === 1
            );
          }),
          cost: [
            {
              type: "currencies",
              item: "cumMotes",
              amount: 500_000_000,
            },
            {
              type: "currencies",
              item: "cumCoin",
              amount: 1_000_000,
            },
          ],
        },
        {
          name: "Pizza Parties",
          infoText:
            "Your Script Kiddies have been complaining about their pay. Promise them to think about it and double their pizza parties from once a year to twice a year. Doubles their effectiveness",
          isVisible: computed(() => {
            const { currencies } = useCurrenciesStore();
            const { upgrades } = useUpgradesStore();

            return (
              currencies.cumCoin.maxObtained >= 50_000_000 &&
              upgrades.borpaScriptKiddiesUpgrade.amount === 2
            );
          }),
          cost: [
            {
              type: "currencies",
              item: "cumCoin",
              amount: 45_000_000,
            },
          ],
        },
      ],
    },
    // 50_000 Cum Coin
    // 25_000_000 Cum Coin
    // 100_000_000 Cum Coin
    borpaBootcampGradsUpgrade: {
      amount: 0,
      purchase,
      tiers: [
        {
          name: "Leetcum Subscription",
          infoText:
            "Buy Leetcum subscriptions for your Borpa Bootcamp Grads. Some knowledge on basic algorithms will help them refactor some of their nested loops, doubling their effectiveness",
          isVisible: computed(() => {
            const { currencies } = useCurrenciesStore();
            const { upgrades } = useUpgradesStore();

            return (
              currencies.cumCoin.maxObtained >= 45_000 &&
              upgrades.borpaBootcampGradsUpgrade.amount === 0
            );
          }),
          cost: [
            {
              type: "currencies",
              item: "cumCoin",
              amount: 50_000,
            },
          ],
        },
        {
          name: "Life Coaching",
          infoText:
            "Your Borpa Bootcamp Grads are always looking for ways to optimize and become more efficient, as that is the most important thing in their world. Hire life coaches to tell them to get enough sleep and eat well. It won't be cheap but it will double your Borpa Bootcamp Grads' effectiveness",
          isVisible: computed(() => {
            const { currencies } = useCurrenciesStore();
            const { upgrades } = useUpgradesStore();

            return (
              currencies.cumCoin.maxObtained >= 22_000_000 &&
              upgrades.borpaBootcampGradsUpgrade.amount === 1
            );
          }),
          cost: [
            {
              type: "currencies",
              item: "cumCoin",
              amount: 25_000_000,
            },
          ],
        },
        {
          name: "Adderall",
          infoText:
            "Your Borpa Bootcamp Grads are complaining about distractions at work. There isn't enough in the budget for individual offices. But you know a guy. Get adderall prescriptions for your Borpa Bootcamp Grads, doubling their effectiveness",
          isVisible: computed(() => {
            const { currencies } = useCurrenciesStore();
            const { upgrades } = useUpgradesStore();

            return (
              currencies.cumCoin.maxObtained >= 70_000_000 &&
              upgrades.borpaBootcampGradsUpgrade.amount === 2
            );
          }),
          cost: [
            {
              type: "currencies",
              item: "cumCoin",
              amount: 100_000_000,
            },
          ],
        },
      ],
    },
    // 500_000 Cum Coin
    // 50_000_000 Cum Coin
    // 500_000_000 Cum Coin
    borpaSeniorEngineersUpgrade: {
      amount: 0,
      purchase,
      tiers: [
        {
          name: "Unlimited PTO",
          infoText:
            "Offer unlimited PTO to your Borpa Senior Engineers. Of course, if they take too much, they will reflect poorly on the performance reviews which might lead to a PIP. How much is too much? Great question. The illusion of freedom doubles your Borpa Software Engineer's effectiveness",
          isVisible: computed(() => {
            const { currencies } = useCurrenciesStore();
            const { upgrades } = useUpgradesStore();

            return (
              currencies.cumCoin.maxObtained >= 450_000 &&
              upgrades.borpaSeniorEngineersUpgrade.amount === 0
            );
          }),
          cost: [
            {
              type: "currencies",
              item: "cumCoin",
              amount: 500_000,
            },
          ],
        },
        {
          name: "Cool Office Furniture",
          infoText:
            "Spend way too much money on massive bean bags and arcade cabinets. Your Borpa Senior Engineers will be too busy to use any of it. But having the option available bolsters their loyalty to the company, doubling their effectiveness",
          isVisible: computed(() => {
            const { currencies } = useCurrenciesStore();
            const { upgrades } = useUpgradesStore();

            return (
              currencies.cumCoin.maxObtained >= 45_000_000 &&
              upgrades.borpaSeniorEngineersUpgrade.amount === 1
            );
          }),
          cost: [
            {
              type: "currencies",
              item: "cumCoin",
              amount: 50_000_000,
            },
          ],
        },
        {
          name: "RSUs",
          infoText:
            "Offer a very very small amount of company stock to your Borpa Senior Engineers. It won't give them nearly any voting power, nor make up for the cuts in other areas to help balance the budget, but a sense of ownership will make them think what they do matters, doubling their effectiveness",
          isVisible: computed(() => {
            const { currencies } = useCurrenciesStore();
            const { upgrades } = useUpgradesStore();

            return (
              currencies.cumCoin.maxObtained >= 450_000_000 &&
              upgrades.borpaSeniorEngineersUpgrade.amount === 2
            );
          }),
          cost: [
            {
              type: "currencies",
              item: "cumCoin",
              amount: 500_000_000,
            },
          ],
        },
      ],
    },
    // 100_000_000 Cum Coin
    // 250_000_000 Cum Coin
    // 750_000_000 Cum Coin
    borpaHaxxorsUpgrade: {
      amount: 0,
      purchase,
      tiers: [
        {
          name: "Cumtain Dew",
          infoText:
            "Buy a large supply of Cumtain Dew for your Borpa Haxxors. It's not very nutritious, but the caffeine and B vitamins will enable your Borpa Haxxors to haxxor for longer, doubling their effectiveness",
          isVisible: computed(() => {
            const { currencies } = useCurrenciesStore();
            const { upgrades } = useUpgradesStore();

            return (
              currencies.cumCoin.maxObtained >= 80_000_000 &&
              upgrades.borpaHaxxorsUpgrade.amount === 0
            );
          }),
          cost: [
            {
              type: "currencies",
              item: "cumCoin",
              amount: 100_000_000,
            },
          ],
        },
        {
          name: "Borpette Body Pillows",
          infoText:
            "Buy Borpette Body Pillows for your Borpa Haxxors. This will help them sleep at night and ease their anxiety, doubling their effectiveness",
          isVisible: computed(() => {
            const { currencies } = useCurrenciesStore();
            const { upgrades } = useUpgradesStore();

            return (
              currencies.cumCoin.maxObtained >= 225_000_000 &&
              upgrades.borpaHaxxorsUpgrade.amount === 1
            );
          }),
          cost: [
            {
              type: "currencies",
              item: "cumCoin",
              amount: 250_000_000,
            },
          ],
        },
        {
          name: "Quad Monitor Setups",
          infoText:
            "Buy quad monitor setups for your Borpa Haxxors. More monitors equal more haxxing, doubling their effectiveness",
          isVisible: computed(() => {
            const { currencies } = useCurrenciesStore();
            const { upgrades } = useUpgradesStore();

            return (
              currencies.cumCoin.maxObtained >= 700_000_000 &&
              upgrades.borpaHaxxorsUpgrade.amount === 2
            );
          }),
          cost: [
            {
              type: "currencies",
              item: "cumCoin",
              amount: 750_000_000,
            },
          ],
        },
      ],
    },
    // 275_000_000 Cum Coin
    // 575_000_000 Cum Coin
    // 1_000_000_000 Cum Coin
    borpaStreamersUpgrade: {
      amount: 0,
      purchase,
      tiers: [
        {
          name: "Sick Streaming Overlays",
          infoText:
            'Commission Borpa Artists to create sick streaming overlays for your Borpa Streamers. The perceived increase in effort by the streamer will entice more "donations", doubling Borpa Streamer effectiveness',
          isVisible: computed(() => {
            const { currencies } = useCurrenciesStore();
            const { upgrades } = useUpgradesStore();

            return (
              currencies.cumCoin.maxObtained >= 200_000_000 &&
              upgrades.borpaStreamersUpgrade.amount === 0
            );
          }),
          cost: [
            {
              type: "currencies",
              item: "cumCoin",
              amount: 275_000_000,
            },
          ],
        },
        {
          name: "Profession Emotes",
          infoText:
            'Hit up the Borpa Artists again to commission professionally done custom emotes for the viewers of your Borpa Streamers to spam in the Cumtch.tv chat. The increased viewer participation encourages them to "donate" even more Cum Coin, doubling Borpa Streamer effectiveness',
          isVisible: computed(() => {
            const { currencies } = useCurrenciesStore();
            const { upgrades } = useUpgradesStore();

            return (
              currencies.cumCoin.maxObtained >= 550_000_000 &&
              upgrades.borpaStreamersUpgrade.amount === 1
            );
          }),
          cost: [
            {
              type: "currencies",
              item: "cumCoin",
              amount: 575_000_000,
            },
          ],
        },
        {
          name: "Gambling Streams",
          infoText:
            "Partner with a Cum Coin gambling platform of dubious reputation and legality to sponsor your Borpa Streamers. The mindless viewers will gamble all of their Cum Coin away, some of which will make it in your pocket as kick backs. Doubles Borpa Streamer effectiveness",
          isVisible: computed(() => {
            const { currencies } = useCurrenciesStore();
            const { upgrades } = useUpgradesStore();

            return (
              currencies.cumCoin.maxObtained >= 960_000_000 &&
              upgrades.borpaStreamersUpgrade.amount === 2
            );
          }),
          cost: [
            {
              type: "currencies",
              item: "cumCoin",
              amount: 1_000_000_000,
            },
          ],
        },
      ],
    },
  };

  return { upgrades: ref(upgrades) };
});
