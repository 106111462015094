<template>
  <div class="modal">
    <div class="session-stats-modal">
      <div class="heading">
        <h1>Session Stats</h1>
        <span @click="close">X</span>
      </div>
      <div class="tabs">
        <template v-for="(session, index) in sessions" :key="index">
          <button v-if="session != null" class="tab-button" @click="setTab(index)">
            {{ index + 1 }}
          </button>
        </template>
      </div>
      <div class="session-stats">
        <h2>Session {{ activeTab + 1 }}</h2>
        <div>Session Start: {{ activeSession.start }}</div>
        <div>Session End: {{ activeSession.end }}</div>
        <div>Strokes: {{ activeSession.strokes }}</div>
        <!-- Currencies -->
        <template v-if="activeSession.currencies != null">
          <h2>Currencies Gained</h2>
          <div
            v-for="(currency, currencyKey) in activeSession.currencies"
            :key="currencyKey"
            class="line"
          >
            {{ getLabel("currencies", String(currencyKey) as CumCurrencyKey).padEnd(30, "&nbsp;") }}
            {{ $formatNum(Number(currency.gain)) }}
          </div>
        </template>
        <!-- Buildings -->
        <template v-if="activeSession.buildings != null">
          <h2>Buildings Gained</h2>
          <div
            v-for="(building, buildingKey) in activeSession.buildings"
            :key="buildingKey"
            class="line"
          >
            {{ getLabel("buildings", String(buildingKey) as CumBuildingKey).padEnd(30, "&nbsp;") }}
            {{ $formatNum(Number(building.gain)) }}
          </div>
        </template>
        <!-- Units -->
        <template v-if="activeSession.units != null">
          <h2>Units Gained</h2>
          <div v-for="(unit, unitKey) in activeSession.units" :key="unitKey" class="line">
            {{ getLabel("units", String(unitKey) as CumUnitKey).padEnd(30, "&nbsp;") }}
            {{ $formatNum(Number(unit.gain)) }}
          </div>
        </template>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { computed, ref } from "vue";

import { useCheatsStore } from "@/store/cheats";
import { getLabel } from "@/util/index";
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { CumCurrencyKey } from "@/store/currencies";
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { CumBuildingKey } from "@/store/buildings";
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { CumUnitKey } from "@/store/units";

export default {
  name: "SessionStatsModal",
  setup() {
    const { cheatsActive } = useCheatsStore();
    const localStorageKeys = Object.keys(localStorage);
    const sessions: {
      start?: string;
      end?: string;
      strokes?: number;
      currencies?: { [key: string]: { gain: number } };
      units?: { [key: string]: { gain: number } };
      buildings?: { [key: string]: { gain: number } };
    }[] = [];
    const meta: {
      amount?: number;
    } = {};

    localStorageKeys.forEach((key) => {
      if (key.startsWith("sessions")) {
        const [, maybeIndex, ...keyParts] = key.split(".");
        if (!Number.isNaN(Number(maybeIndex))) {
          const index = Number(maybeIndex) - 1;
          const itemKey = keyParts.pop();

          if (sessions[index] == null) {
            sessions[index] = {};
          }

          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          let curr: { [key: string]: any } = sessions[index];

          keyParts.forEach((part) => {
            if (curr[part] == null) curr[part] = {};
            curr = curr[part];
          });

          let item = localStorage.getItem(key);

          if (itemKey === "start" || itemKey === "end") {
            item = new Date(Number(item)).toLocaleString();
          }

          curr[itemKey as string] = item;
        } else if (maybeIndex === "amount") {
          meta[maybeIndex] = Number(localStorage.getItem(key));
        }
      }
    });

    const activeTab = ref(sessions.length - 1);

    function setTab(index: number) {
      activeTab.value = index;
    }

    const activeSession = computed(() => {
      return sessions[activeTab.value];
    });

    function close() {
      cheatsActive.session = false;
    }

    return {
      activeSession,
      activeTab,
      close,
      getLabel,
      meta,
      sessions,
      setTab,
    };
  },
};
</script>

<style lang="scss">
.session-stats-modal {
  width: 420px;
  height: 100%;
  overflow-y: scroll;
  padding-right: 16px;

  .heading {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-right: 4px;

    span {
      padding: 8px;
      cursor: pointer;
    }
  }

  .tabs {
    display: flex;
    flex-wrap: wrap;

    .tab-button {
      margin-right: 4px;
      margin-bottom: 4px;
    }
  }

  .session-stats {
    h2 {
      padding-top: 12px;
      padding-bottom: 12px;
      font-size: 18px;
      font-weight: 600;
    }

    .line {
      margin-bottom: 4px;
      border-bottom: 1px dotted grey;
    }
  }

  h1 {
    margin-bottom: 10px;
    margin-top: 4px;
    font-size: 20px;
    font-weight: 600;
  }
}
</style>
