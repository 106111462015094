import { normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(['progress-bar-container', { gayge: _ctx.cheatsActive.gayge }])
  }, [
    _createElementVNode("div", {
      class: _normalizeClass(['progress-bar', { gayge: _ctx.cheatsActive.gayge }]),
      style: _normalizeStyle(_ctx.barPosition)
    }, null, 6)
  ], 2))
}