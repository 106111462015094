import { defineStore, storeToRefs } from "pinia";
import { computed, ComputedRef, Ref, ref, watch } from "vue";

import { CumBuildingKey, useBuildingsStore } from "@/store/buildings";
import { useCheatsStore } from "@/store/cheats";
import { CumCurrencyKey, useCurrenciesStore } from "@/store/currencies";
import { useEventsStore } from "@/store/events";
import { CumUnitKey, useUnitsStore } from "@/store/units";
import { useUpgradesStore } from "@/store/upgrades";
import { applySaveMigrations } from "@/util/saveMigration";

export type Entity = {
  name: string;
  infoText: ComputedRef<string> | string;
  amount: number;
  isVisible: ComputedRef<boolean>;
  purchase(amount: number): void;
};

export type Cost = {
  type: "buildings" | "units" | "currencies";
  item: CumBuildingKey | CumUnitKey | CumCurrencyKey;
  amount: number;
};

export const useGameStore = defineStore("game", () => {
  // Stores
  const { buildings } = useBuildingsStore();
  const { addCheatCodeEvent } = useCheatsStore();
  const { cheatCode } = storeToRefs(useCheatsStore());
  const { currencies } = useCurrenciesStore();
  const {
    addEventStream,
    clearEventsStream,
    onTick: eventOnTick,
    setNextEventTick,
    updateTickRate,
  } = useEventsStore();
  const { units } = useUnitsStore();
  const { upgrades } = useUpgradesStore();

  const statefulEntities = {
    buildings,
    currencies,
    units,
    upgrades,
  };

  const currentSession = ref(-1);

  function addSessionGain(gainKey: string, gain: number) {
    if (gain === 0) return;

    const storageKey = `sessions.${currentSession.value}.${gainKey}.gain`;
    const currentGain = localStorage.getItem(storageKey);
    const newGain = Number(currentGain) + gain;

    localStorage.setItem(storageKey, String(newGain));
  }

  // Main Loop
  const mainLoopInterval: Ref<null | NodeJS.Timeout> = ref(null);
  let tick = 0;
  const tickRate = ref(100);
  const tickRateFactor = computed(() => tickRate.value / 100);

  // Save game
  const persistState = () => {
    localStorage.setItem(`sessions.${currentSession.value}.end`, String(Date.now()));

    Object.entries(statefulEntities).forEach(([entitiesKey, entities]) => {
      for (const [entityKey, entity] of Object.entries(entities)) {
        const storageKey = `${entitiesKey}.${entityKey}`;

        localStorage.setItem(storageKey, String(entity.amount));

        if (entity.maxObtained != null) {
          localStorage.setItem(`${storageKey}.maxObtained`, String(entity.maxObtained));
        }

        if (entity.active != null) {
          localStorage.setItem(`${storageKey}.active`, String(entity.active));
        }
      }
    });
  };

  // Load Game
  const restoreState = () => {
    const storedSessionsAmount = localStorage.getItem("sessions.amount");
    currentSession.value = Number(storedSessionsAmount) + 1;
    localStorage.setItem("sessions.amount", String(currentSession.value));
    localStorage.setItem(`sessions.${currentSession.value}.start`, String(Date.now()));
    localStorage.setItem(`sessions.${currentSession.value}.strokes`, "0");

    Object.entries(statefulEntities).forEach(([entitiesKey, entities]) => {
      for (const [entityKey, entity] of Object.entries(entities)) {
        const storageKey = `${entitiesKey}.${entityKey}`;

        const amount = localStorage.getItem(storageKey);
        if (amount != null) entity.amount = Number(amount);

        const maxObtained = localStorage.getItem(`${storageKey}.maxObtained`);
        if (maxObtained != null) entity.maxObtained = Number(maxObtained);

        const active = localStorage.getItem(`${storageKey}.active`);
        if (active != null) entity.active = Boolean(active);
      }
    });
  };

  // Reset game
  const resetGame = () => {
    const firstOpened = localStorage.getItem("firstOpened");
    currentSession.value = 1;
    localStorage.setItem("sessions.amount", String(currentSession.value));
    localStorage.setItem(`sessions.${currentSession.value}.start`, String(Date.now()));
    localStorage.setItem(`sessions.${currentSession.value}.strokes`, "0");

    localStorage.clear();

    if (firstOpened != null) {
      localStorage.setItem("firstOpened", firstOpened);
    } else {
      localStorage.setItem("firstOpened", String(Date.now()));
    }

    [buildings, currencies, units, upgrades].forEach((entities) => {
      for (const entity of Object.values(entities)) {
        entity.amount = 0;

        if (entity.maxObtained) {
          entity.maxObtained = 0;
        }
      }
    });

    buildings.cumMines.amount = 1;
    units.borpaMiners.amount = 1;
    currencies.cums.amount = 100;

    clearEventsStream();
    addEventStream(
      "You were browsing discount real estate websites when you found a deal you couldn't pass up. Someone was giving away their cum mine on one condition, to succeed where they failed and strike it rich in cums. You convince your borpa friend Jerry to come along and join you in your quest. Good luck.",
    );

    persistState();
  };

  watch(tickRate, (oldTickRate, newTickRate) => {
    if (typeof tickRate.value !== "number" || Number.isNaN(tickRate)) {
      tickRate.value = 100;
      return;
    }

    if (tickRate.value < 10) tickRate.value = 10;
    if (tickRate.value > 100000) tickRate.value = 100000;

    if (mainLoopInterval.value !== null) {
      clearInterval(mainLoopInterval.value);
      mainLoopInterval.value = null;
    }

    updateTickRate(oldTickRate, newTickRate, tick);
    startMainLoop();
  });

  const upgradeTiers = Object.values(upgrades)
    .map((upgrade) => upgrade.tiers)
    .flat();

  const onTickEntities = [
    ...Object.values(units),
    ...upgradeTiers,
    ...Object.values(currencies),
  ].filter((entity) => "onTick" in entity);

  function mainLoop() {
    tick++;

    for (let i = 0; i < onTickEntities.length; i += 1) {
      const entity = onTickEntities[i];
      if (entity.onTick != null) entity.onTick(tick);
    }

    eventOnTick(tick);

    if (tick % Math.ceil(10 * tickRateFactor.value) === 0) persistState();
    // Clear cheatCode periodically so it doesn't get huge if someone
    // types a bunch for some reason
    if (tick % 1000 === 0) cheatCode.value = "";
  }

  function startMainLoop() {
    if (mainLoopInterval.value == null) {
      mainLoopInterval.value = setInterval(mainLoop, tickRate.value);
    }
  }

  // Reduce tickRate to 2 seconds when tab is inactive
  let cachedTickRate = tickRate.value;
  const visibilityHandler = () => {
    if (document.hidden) {
      cachedTickRate = tickRate.value;
      tickRate.value = 2000;
    } else {
      tickRate.value = cachedTickRate;
    }
  };

  function addVisibilityEvent() {
    document.addEventListener("visibilitychange", visibilityHandler);
  }

  const init = () => {
    applySaveMigrations();
    restoreState();
    // Gives initial borpa miner and event
    if (currencies.cums.amount === 0 && currencies.cums.maxObtained === 0) resetGame();
    setNextEventTick(0);
    startMainLoop();
    addCheatCodeEvent();
    addVisibilityEvent();
  };

  return {
    addSessionGain,
    currentSession,
    init,
    mainLoop,
    resetGame,
    tickRate,
    tickRateFactor,
  };
});
