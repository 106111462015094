import { defineStore } from "pinia";
import { computed, ComputedRef, ref } from "vue";

import { CumBuildingKey, useBuildingsStore } from "@/store/buildings";
import { CumCurrencyKey, useCurrenciesStore } from "@/store/currencies";
import { Cost, Entity } from "@/store/game";
import { tryPurchase, give, applyTickEffects, getInfoTextStrings } from "@/util";

export type TickEffect = {
  type: "buildings" | "units" | "currencies";
  item: CumBuildingKey | CumUnitKey | CumCurrencyKey;
  upgrade?: string;
  min: number;
  max: number;
};

export interface CumUnit extends Entity {
  max: ComputedRef<number>;
  cost: Cost[];
  tickEffects: TickEffect[];
  onTick(tick: number): void;
  give: (amount: number) => void;
}

export type CumUnitKey =
  | "borpaMiners"
  | "borpaWorkers"
  | "borpaGardeners"
  | "borpaGamers"
  | "borpaCoomers"
  | "borpaMystics"
  | "cumboElementals"
  | "borpaRecruiters"
  | "cumboGenies"
  | "cumLords"
  | "borpaScriptKiddies"
  | "borpaBootcampGrads"
  | "borpaSeniorEngineers"
  | "borpaHaxxors"
  | "borpaStreamers";

export const useUnitsStore = defineStore("units", () => {
  function onTick(this: CumUnit, tick: number) {
    if (this.amount === 0) return;
    applyTickEffects(this.tickEffects, this.amount, tick);
  }

  function purchase(this: CumUnit, amount: number) {
    if (this.amount + amount > Number(this.max)) return;
    const purchased = tryPurchase(this.cost, amount);

    if (purchased) {
      this.give(amount);
    }
  }

  const units: { [key: string]: CumUnit } = {
    // Unit for Cum Mine
    borpaMiners: {
      name: "Borpa Miner",
      infoText: computed(() => {
        const { units } = useUnitsStore();
        return `Each borpa miner will gather ${getInfoTextStrings(units.borpaMiners.tickEffects)}`;
      }),
      amount: 0,
      max: computed(() => {
        const { buildings } = useBuildingsStore();

        return buildings.cumMines.amount * 10;
      }),
      isVisible: computed(() => true),
      cost: [
        {
          type: "currencies",
          item: "cums",
          amount: 50,
        },
      ],
      tickEffects: [
        {
          type: "currencies",
          item: "cums",
          upgrade: "minerUpgrade",
          min: 1,
          max: 3,
        },
      ],
      onTick,
      purchase,
      give: give("units.borpaMiners"),
    },
    // Unit for Cum Factory
    borpaWorkers: {
      name: "Borpa Worker",
      infoText: computed(() => {
        const { units } = useUnitsStore();

        return `Each borpa worker will fabricate ${getInfoTextStrings(
          units.borpaWorkers.tickEffects,
        )}`;
      }),
      amount: 0,
      max: computed(() => {
        const { buildings } = useBuildingsStore();

        return buildings.cumFactories.amount * 100;
      }),
      isVisible: computed(() => {
        const { buildings } = useBuildingsStore();
        const { currencies } = useCurrenciesStore();
        const { units } = useUnitsStore();

        return (
          units.borpaWorkers.amount > 0 ||
          buildings.cumFactories.amount > 0 ||
          currencies.cums.maxObtained > 4_500
        );
      }),
      cost: [
        {
          type: "currencies",
          item: "cums",
          amount: 2_000,
        },
      ],
      tickEffects: [
        {
          type: "currencies",
          item: "cums",
          upgrade: "workerUpgrade",
          min: 75,
          max: 125,
        },
      ],
      onTick,
      purchase,
      give: give("units.borpaWorkers"),
    },
    // Unit for Cum Garden
    borpaGardeners: {
      name: "Borpa Gardener",
      infoText: computed(() => {
        const { units } = useUnitsStore();

        return `Each borpa gardener will gather ${getInfoTextStrings(
          units.borpaGardeners.tickEffects,
        )}`;
      }),
      amount: 0,
      max: computed(() => {
        const { buildings } = useBuildingsStore();

        return buildings.cumGardens.amount * 2;
      }),
      isVisible: computed(() => {
        const { buildings } = useBuildingsStore();
        const { currencies } = useCurrenciesStore();
        const { units } = useUnitsStore();

        return (
          units.borpaGardeners.amount > 0 ||
          buildings.cumGardens.amount > 0 ||
          currencies.cums.maxObtained > 45_000
        );
      }),
      cost: [
        {
          type: "currencies",
          item: "cums",
          amount: 50_000,
        },
      ],
      tickEffects: [
        {
          type: "currencies",
          item: "cums",
          upgrade: "gardenUpgrade",
          min: 3_500,
          max: 4_000,
        },
      ],
      onTick,
      purchase,
      give: give("units.borpaGardeners"),
    },
    // Unit for 10K PC
    borpaGamers: {
      name: "Borpa Gamer",
      infoText: computed(() => {
        const { units } = useUnitsStore();

        return `Each borpa gamer will game ${getInfoTextStrings(units.borpaGamers.tickEffects)}`;
      }),
      amount: 0,
      max: computed(() => {
        const { buildings } = useBuildingsStore();

        return buildings.tenKPcs.amount;
      }),
      isVisible: computed(() => {
        const { buildings } = useBuildingsStore();
        const { currencies } = useCurrenciesStore();
        const { units } = useUnitsStore();

        return (
          units.borpaGamers.amount > 0 ||
          buildings.tenKPcs.amount > 0 ||
          currencies.cums.maxObtained > 180_000
        );
      }),
      cost: [
        {
          type: "currencies",
          item: "cums",
          amount: 1,
        },
      ],
      tickEffects: [
        {
          type: "currencies",
          item: "cums",
          upgrade: "gamerUpgrade",
          min: 30_000,
          max: 35_000,
        },
      ],
      onTick,
      purchase,
      give: give("units.borpaGamers"),
    },
    // Unit for OnlyCums Subscription
    borpaCoomers: {
      name: "Borpa Coomer",
      infoText: computed(() => {
        const { units } = useUnitsStore();
        return `Each borpa coomer will coom ${getInfoTextStrings(units.borpaCoomers.tickEffects)}`;
      }),
      amount: 0,
      max: computed(() => {
        const { buildings } = useBuildingsStore();

        return buildings.onlycumsSubscriptions.amount * 10;
      }),
      isVisible: computed(() => {
        const { buildings } = useBuildingsStore();
        const { currencies } = useCurrenciesStore();
        const { units } = useUnitsStore();

        return (
          units.borpaCoomers.amount > 0 ||
          buildings.onlycumsSubscriptions.amount > 0 ||
          currencies.cums.maxObtained > 450_000
        );
      }),
      cost: [
        {
          type: "currencies",
          item: "cums",
          amount: 500_000,
        },
      ],
      tickEffects: [
        {
          type: "currencies",
          item: "cums",
          upgrade: "coomerUpgrade",
          min: 175_000,
          max: 250_000,
        },
      ],
      onTick,
      purchase,
      give: give("units.borpaCoomers"),
    },
    // Unit for Summoning Circle
    borpaMystics: {
      name: "Borpa Mystic",
      infoText: computed(() => {
        const { units } = useUnitsStore();
        return `Each borpa mystic will apparate ${getInfoTextStrings(
          units.borpaMystics.tickEffects,
        )}`;
      }),
      amount: 0,
      max: computed(() => {
        const { buildings } = useBuildingsStore();

        return buildings.summoningCircles.amount * 10;
      }),
      isVisible: computed(() => {
        const { buildings } = useBuildingsStore();
        const { currencies } = useCurrenciesStore();
        const { units } = useUnitsStore();

        return (
          units.borpaMystics.amount > 0 ||
          buildings.summoningCircles.amount > 0 ||
          currencies.cumMotes.maxObtained > 8
        );
      }),
      cost: [
        {
          type: "currencies",
          item: "cumMotes",
          amount: 20,
        },
      ],
      tickEffects: [
        {
          type: "currencies",
          item: "cumMotes",
          upgrade: "mysticUpgrade",
          min: 0.5,
          max: 1,
        },
      ],
      onTick,
      purchase,
      give: give("units.borpaMystics"),
    },
    // Unit for Elemental Bracelet
    cumboElementals: {
      name: "Cumbo Elemental",
      infoText: computed(() => {
        const { units } = useUnitsStore();
        return `Each cumbo elemental will emanate ${getInfoTextStrings(
          units.cumboElementals.tickEffects,
        )}`;
      }),
      amount: 0,
      max: computed(() => {
        const { buildings } = useBuildingsStore();

        return buildings.elementalBracelets.amount * 2;
      }),
      isVisible: computed(() => {
        const { buildings } = useBuildingsStore();
        const { currencies } = useCurrenciesStore();
        const { units } = useUnitsStore();

        return (
          units.cumboElementals.amount > 0 ||
          buildings.elementalBracelets.amount > 0 ||
          currencies.cumMotes.maxObtained > 800
        );
      }),
      cost: [
        {
          type: "currencies",
          item: "cumMotes",
          amount: 1_000,
        },
        {
          type: "units",
          item: "borpaMiners",
          amount: 666,
        },
        {
          type: "units",
          item: "borpaMystics",
          amount: 10,
        },
      ],
      tickEffects: [
        {
          type: "currencies",
          item: "cumMotes",
          upgrade: "elementalUpgrade",
          min: 40,
          max: 80,
        },
      ],
      onTick,
      purchase,
      give: give("units.cumboElementals"),
    },
    // Unit for WFH Setup
    borpaRecruiters: {
      name: "Borpa Recruiter",
      infoText: computed(() => {
        const { units } = useUnitsStore();

        return `Each Borpa Recruiter adds a chance of recruiting ${getInfoTextStrings(
          units.borpaRecruiters.tickEffects,
        )}`;
      }),
      amount: 0,
      max: computed(() => {
        const { buildings } = useBuildingsStore();

        return buildings.WFHSetups.amount;
      }),
      isVisible: computed(() => {
        const { buildings } = useBuildingsStore();
        const { currencies } = useCurrenciesStore();
        const { units } = useUnitsStore();

        return (
          units.borpaRecruiters.amount > 0 ||
          buildings.WFHSetups.amount > 0 ||
          currencies.cumMotes.maxObtained > 1_800
        );
      }),
      cost: [
        {
          type: "currencies",
          item: "cumMotes",
          amount: 2_000,
        },
        {
          type: "units",
          item: "borpaMiners",
          amount: 100,
        },
        {
          type: "units",
          item: "borpaWorkers",
          amount: 50,
        },
        {
          type: "units",
          item: "borpaGardeners",
          amount: 20,
        },
        {
          type: "units",
          item: "borpaGamers",
          amount: 10,
        },
        {
          type: "units",
          item: "borpaCoomers",
          amount: 5,
        },
      ],
      tickEffects: [
        {
          type: "units",
          item: "borpaMiners",
          upgrade: "recruiterUpgrade",
          min: 150,
          max: 200,
        },
        {
          type: "units",
          item: "borpaWorkers",
          upgrade: "recruiterUpgrade",
          min: 30,
          max: 40,
        },
        {
          type: "units",
          item: "borpaGardeners",
          upgrade: "recruiterUpgrade",
          min: 10,
          max: 15,
        },
        {
          type: "units",
          item: "borpaGamers",
          upgrade: "recruiterUpgrade",
          min: 5,
          max: 10,
        },
        {
          type: "units",
          item: "borpaCoomers",
          upgrade: "recruiterUpgrade",
          min: 2,
          max: 3,
        },
      ],
      onTick,
      purchase,
      give: give("units.borpaRecruiters"),
    },
    // Unit for Genie Lamp
    cumboGenies: {
      name: "Cumbo Genie",
      infoText: computed(() => {
        const { units } = useUnitsStore();

        return `Cumbo Genies are powerful beings who can use their wish granting power to generate unreal amounts of cum, if you can manage to subjugate one. Each cumbo elemental will emanate ${getInfoTextStrings(
          units.cumboGenies.tickEffects,
        )}`;
      }),
      amount: 0,
      max: computed(() => {
        const { buildings } = useBuildingsStore();

        return buildings.genieLamps.amount * 3;
      }),
      isVisible: computed(() => {
        const { buildings } = useBuildingsStore();
        const { currencies } = useCurrenciesStore();
        const { units } = useUnitsStore();

        return (
          units.cumboGenies.amount > 0 ||
          buildings.genieLamps.amount > 0 ||
          currencies.cumMotes.maxObtained > 450_000
        );
      }),
      cost: [
        {
          type: "currencies",
          item: "cumMotes",
          amount: 20_000,
        },
        {
          type: "units",
          item: "cumboElementals",
          amount: 20,
        },
      ],
      tickEffects: [
        {
          type: "currencies",
          item: "cumMotes",
          upgrade: "genieUpgrade",
          min: 5_000,
          max: 17_500,
        },
      ],
      onTick,
      purchase,
      give: give("units.cumboGenies"),
    },
    // Unit for Cum Gauntlet
    cumLords: {
      name: "Cumlord",
      infoText: computed(() => {
        const { units } = useUnitsStore();

        return `Cumlords are powerful underworld kings that command vast fiefs. If you can trick one into wearing a cum gauntlet, you will be able to instruct them to produce cum motes for you. Each Cumlord will produce ${getInfoTextStrings(
          units.cumLords.tickEffects,
        )}`;
      }),
      amount: 0,
      max: computed(() => {
        const { buildings } = useBuildingsStore();

        return buildings.cumGauntlets.amount * 10;
      }),
      isVisible: computed(() => {
        const { buildings } = useBuildingsStore();
        const { currencies } = useCurrenciesStore();
        const { units } = useUnitsStore();

        return (
          units.cumLords.amount > 0 ||
          buildings.cumGauntlets.amount > 0 ||
          currencies.cumMotes.maxObtained > 150_000_000
        );
      }),
      cost: [
        {
          type: "currencies",
          item: "cumMotes",
          amount: 2_000_000,
        },
        {
          type: "units",
          item: "borpaCoomers",
          amount: 200_000,
        },
      ],
      tickEffects: [
        {
          type: "currencies",
          item: "cumMotes",
          upgrade: "cumLordUpgrade",
          min: 450_000,
          max: 600_000,
        },
      ],
      onTick,
      purchase,
      give: give("units.cumLords"),
    },
    // Unit for BorpaBook Pro
    borpaScriptKiddies: {
      name: "Borpa Script Kiddie",
      infoText: computed(() => {
        const { units } = useUnitsStore();

        return `Borpa Script Kiddies know how to run basic Cum Coin miners. They aren't very effective, but everyone needs to start from somewhere. They can scrounge up ${getInfoTextStrings(
          units.borpaScriptKiddies.tickEffects,
        )}`;
      }),
      amount: 0,
      max: computed(() => {
        const { buildings } = useBuildingsStore();

        return buildings.borpaBookPros.amount * 5;
      }),
      isVisible: computed(() => {
        const { buildings } = useBuildingsStore();
        const { currencies } = useCurrenciesStore();
        const { units } = useUnitsStore();

        return (
          units.borpaScriptKiddies.amount > 0 ||
          buildings.borpaBookPros.amount > 0 ||
          currencies.cumCoin.maxObtained >= 1
        );
      }),
      cost: [
        {
          type: "currencies",
          item: "cumCoin",
          amount: 1,
        },
      ],
      tickEffects: [
        {
          type: "currencies",
          item: "cumCoin",
          upgrade: "borpaScriptKiddiesUpgrade",
          min: 0.02,
          max: 0.025,
        },
      ],
      onTick,
      purchase,
      give: give("units.borpaScriptKiddies"),
    },
    // Unit for 15k Student Loan
    borpaBootcampGrads: {
      name: "Borpa Bootcamp Grad",
      infoText: computed(() => {
        const { units } = useUnitsStore();

        return `Borpa Bootcamp Grads know their way around a CLI. They write spaghetti code and nest loops wherever they can, but their ability to mine Cum Coin is considerably better than a script kiddie. They get ${getInfoTextStrings(
          units.borpaBootcampGrads.tickEffects,
        )}`;
      }),
      amount: 0,
      max: computed(() => {
        const { buildings } = useBuildingsStore();

        return buildings.studentLoans.amount * 1;
      }),
      isVisible: computed(() => {
        const { buildings } = useBuildingsStore();
        const { currencies } = useCurrenciesStore();
        const { units } = useUnitsStore();

        return (
          units.borpaBootcampGrads.amount > 0 ||
          buildings.studentLoans.amount > 0 ||
          currencies.cumCoin.maxObtained >= 4_500
        );
      }),
      cost: [
        {
          type: "currencies",
          item: "cumCoin",
          amount: 5_000,
        },
        {
          type: "units",
          item: "borpaScriptKiddies",
          amount: 1,
        },
      ],
      tickEffects: [
        {
          type: "currencies",
          item: "cumCoin",
          upgrade: "borpaBootcampGradsUpgrade",
          min: 75,
          max: 125,
        },
      ],
      onTick,
      purchase,
      give: give("units.borpaBootcampGrads"),
    },
    // Unit for Two Years' Experience
    borpaSeniorEngineers: {
      name: "Borpa Senior Engineer",
      infoText: computed(() => {
        const { units } = useUnitsStore();

        return `Borpa Senior Engineers have seen it all. With two years of experience, they are ready to build the next Cumstagram, or Cumflix. Each Borpa Senior Engineer will produce ${getInfoTextStrings(
          units.borpaSeniorEngineers.tickEffects,
        )}`;
      }),
      amount: 0,
      max: computed(() => {
        const { buildings } = useBuildingsStore();

        return buildings.twoYearsExperience.amount * 1;
      }),
      isVisible: computed(() => {
        const { buildings } = useBuildingsStore();
        const { currencies } = useCurrenciesStore();
        const { units } = useUnitsStore();

        return (
          units.borpaSeniorEngineers.amount > 0 ||
          buildings.twoYearsExperience.amount > 0 ||
          currencies.cumCoin.maxObtained >= 45_000
        );
      }),
      cost: [
        {
          type: "currencies",
          item: "cumCoin",
          amount: 50_000,
        },
        {
          type: "units",
          item: "borpaBootcampGrads",
          amount: 1,
        },
      ],
      tickEffects: [
        {
          type: "currencies",
          item: "cumCoin",
          upgrade: "borpaSeniorEngineersUpgrade",
          min: 3_250,
          max: 4_250,
        },
      ],
      onTick,
      purchase,
      give: give("units.borpaSeniorEngineers"),
    },
    // Unit for Two Years' Experience
    borpaHaxxors: {
      name: "Borpa Haxxor",
      infoText: computed(() => {
        const { units } = useUnitsStore();

        return `Borpa Haxxors spend all their day on the Cum Web, a secretive network presenting opportunities to do things without being tracked. Their dubious morals put you off but enable the haxxor's to obtain massive amounts of Cum Coin. Each Borpa Haxxor will get you ${getInfoTextStrings(
          units.borpaHaxxors.tickEffects,
        )}`;
      }),
      amount: 0,
      max: computed(() => {
        const { buildings } = useBuildingsStore();

        return buildings.torBrowsers.amount * 10;
      }),
      isVisible: computed(() => {
        const { buildings } = useBuildingsStore();
        const { currencies } = useCurrenciesStore();
        const { units } = useUnitsStore();

        return (
          units.borpaHaxxors.amount > 0 ||
          buildings.torBrowsers.amount > 0 ||
          currencies.cumCoin.maxObtained >= 4_500_000
        );
      }),
      cost: [
        {
          type: "currencies",
          item: "cumCoin",
          amount: 5_000_000,
        },
      ],
      tickEffects: [
        {
          type: "currencies",
          item: "cumCoin",
          upgrade: "borpaHaxxorsUpgrade",
          min: 300_000,
          max: 325_000,
        },
      ],
      onTick,
      purchase,
      give: give("units.borpaHaxxors"),
    },
    // Unit for Two Years' Experience
    borpaStreamers: {
      name: "Borpa Streamer",
      infoText: computed(() => {
        const { units } = useUnitsStore();

        return `Why mine or steal Cum Coin when you can get rubes to send you theirs instead? Borpa Streamers broadcast to Cumtch.tv all day and night and pretend to be their viewers friends. Each Borpa Streamer will entice about ${getInfoTextStrings(
          units.borpaStreamers.tickEffects,
        )} in "donations"`;
      }),
      amount: 0,
      max: computed(() => {
        const { buildings } = useBuildingsStore();

        return buildings.streamingSetups.amount * 2;
      }),
      isVisible: computed(() => {
        const { buildings } = useBuildingsStore();
        const { currencies } = useCurrenciesStore();
        const { units } = useUnitsStore();

        return (
          units.borpaStreamers.amount > 0 ||
          buildings.streamingSetups.amount > 0 ||
          currencies.cumCoin.maxObtained >= 45_000_000
        );
      }),
      cost: [
        {
          type: "currencies",
          item: "cumCoin",
          amount: 50_000_000,
        },
      ],
      tickEffects: [
        {
          type: "currencies",
          item: "cumCoin",
          upgrade: "borpaStreamersUpgrade",
          min: 5_000_000,
          max: 6_000_000,
        },
      ],
      onTick,
      purchase,
      give: give("units.borpaStreamers"),
    },
  };

  return { units: ref(units) };
});
