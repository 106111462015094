<template>
  <div class="modal">
    <div class="cost-window">
      <div class="header">
        <h2>Costs in Cums</h2>
        <span class="close" @click="close">X</span>
      </div>
      <br />
      <div class="line" v-for="(cost, entity) in cumCosts" :key="entity">
        {{ `${entity}:`.padEnd(22, "&nbsp;") }}
        {{ $formatNum(cost) }}
      </div>
      <br />
      <h2>Cums Per Second Per Unit (Base / Not upgraded)</h2>
      <br />
      <div class="line" v-for="(cps, unit) in cumsPerSecond" :key="unit">
        {{ `${unit}:`.padEnd(22, "&nbsp;") }}
        {{ $formatNum(cps).padEnd(7, "&nbsp;") }}
        CPS
      </div>
      <br />
      <h2>Cost Per CPS (Base / Not upgraded)</h2>
      <br />
      <div class="line" v-for="(cost, unit) in costPerCPS" :key="unit">
        {{ `${unit}:`.padEnd(22, "&nbsp;") }}
        {{ $formatNum(cost).padEnd(7, "&nbsp;") }}
        Cums Per CPS
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { CumBuilding, useBuildingsStore } from "@/store/buildings";
import { CumUnit, TickEffect, useUnitsStore } from "@/store/units";
import { useUpgradesStore } from "@/store/upgrades";
import { useCheatsStore } from "@/store/cheats";
import { getStoreFromType } from "@/util";
import { Cost } from "@/store/game";

const cumCosts: { [key: string]: number } = {
  cums: 1,
  cumMotes: 1_000_000_000,
  cumCoin: 1_000_000_000 * 1_000_000_000,
};

const cumsPerSecond: { [key: string]: number } = {};
const costPerCPS: { [key: string]: number } = {};

export default {
  name: "CostWindow",
  setup() {
    const { buildings } = useBuildingsStore();
    const { units } = useUnitsStore();
    const { upgrades } = useUpgradesStore();
    const { cheatsActive } = useCheatsStore();

    function close() {
      cheatsActive.cost = false;
    }

    function findCumCost(costs: Cost[]): number {
      let cumsCost = 0;

      costs.forEach((cost) => {
        const { type, item, amount } = cost;
        if (cumCosts[item] != null) {
          cumsCost += cumCosts[item] * amount;
        } else {
          const store = getStoreFromType(type) as
            | { [key: string]: CumBuilding }
            | { [key: string]: CumUnit };

          if (store != null) {
            cumsCost += findCumCost(store[item].cost);
          }
        }
      });

      return cumsCost;
    }

    function findCumsPerSecond(tickEffects: TickEffect[]): number {
      let cumsPerSecond = 0;

      tickEffects.forEach((tickEffect) => {
        const { item, min, max } = tickEffect;
        let average = (min + max) / 2;
        // per minute to per second
        average /= 60;
        const cumCost = cumCosts[item];
        average *= cumCost;
        cumsPerSecond += average;
      });

      return cumsPerSecond;
    }

    const buildingCostsForUnits: { [key: string]: Cost } = {
      borpaMiners: {
        type: "buildings",
        item: "cumMines",
        amount: 1 / 10,
      },
      borpaWorkers: {
        type: "buildings",
        item: "cumFactories",
        amount: 1 / 100,
      },
      borpaGardeners: {
        type: "buildings",
        item: "cumGardens",
        amount: 1 / 2,
      },
      borpaGamers: {
        type: "buildings",
        item: "tenKPcs",
        amount: 1,
      },
      borpaCoomers: {
        type: "buildings",
        item: "onlycumsSubscriptions",
        amount: 1 / 10,
      },
      borpaMystics: {
        type: "buildings",
        item: "summoningCircles",
        amount: 1 / 10,
      },
      cumboElementals: {
        type: "buildings",
        item: "elementalBracelets",
        amount: 1 / 2,
      },
      borpaRecruiters: {
        type: "buildings",
        item: "WFHSetups",
        amount: 1,
      },
      cumboGenies: {
        type: "buildings",
        item: "genieLamps",
        amount: 1 / 3,
      },
      cumLords: {
        type: "buildings",
        item: "cumGauntlets",
        amount: 1 / 10,
      },
      borpaScriptKiddies: {
        type: "buildings",
        item: "borpaBookPros",
        amount: 1 / 5,
      },
      borpaBootcampGrads: {
        type: "buildings",
        item: "studentLoans",
        amount: 1,
      },
      borpaSeniorEngineers: {
        type: "buildings",
        item: "twoYearsExperience",
        amount: 1,
      },
      borpaHaxxors: {
        type: "buildings",
        item: "torBrowsers",
        amount: 1 / 10,
      },
      borpaStreamers: {
        type: "buildings",
        item: "streamingSetups",
        amount: 1 / 2,
      },
    };

    [buildings, units, upgrades].forEach((entities) => {
      Object.entries(entities).forEach(([key, entity]) => {
        if (entity.cost != null) {
          const costs = [...entity.cost];

          if (buildingCostsForUnits[key]) {
            costs.push(buildingCostsForUnits[key]);
          }

          cumCosts[key] = findCumCost(costs);
        }
      });
    });

    Object.entries(units).forEach(([key, unit]) => {
      cumsPerSecond[key] = findCumsPerSecond(unit.tickEffects);
    });

    Object.entries(cumsPerSecond).forEach(([unit, cps]) => {
      costPerCPS[unit] = cumCosts[unit] / cps;
    });

    return { cumCosts, cumsPerSecond, costPerCPS, close };
  },
};
</script>

<style lang="scss">
.cost-window {
  height: 100%;
  width: 100%;
  overflow-y: auto;

  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .close {
      margin-right: 20px;
      padding: 8px;
      cursor: pointer;
    }
  }

  .line {
    margin-bottom: 4px;
    border-bottom: 1px dotted grey;
  }
}
</style>
