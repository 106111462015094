import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-f9434c00"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "container" }
const _hoisted_2 = { class: "gain-wrapper" }
const _hoisted_3 = {
  key: 0,
  class: "gain"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ProgressBar = _resolveComponent("ProgressBar")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_ProgressBar, {
      progress: _ctx.cumProgress.amount
    }, null, 8, ["progress"]),
    _createElementVNode("div", _hoisted_2, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.gainedCums, (gain, index) => {
        return (_openBlock(), _createElementBlock(_Fragment, {
          key: `${gain}-${index}`
        }, [
          gain
            ? (_openBlock(), _createElementBlock("span", _hoisted_3, "+" + _toDisplayString(gain) + " Cums!", 1))
            : _createCommentVNode("", true)
        ], 64))
      }), 128))
    ])
  ]))
}