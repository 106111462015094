const migrations: (() => void)[] = [
  () => {
    // Old keys to new keys
    const entityKeyUpdates: { [key: string]: { [key: string]: string } } = {
      units: {
        borpaGamer: "borpaGamers",
        borpaCoomer: "borpaCoomers",
        borpaMystic: "borpaMystics",
        cumboElemental: "cumboElementals",
        borpaRecruiter: "borpaRecruiters",
      },
      buildings: {
        tenKPc: "tenKPcs",
        onlycumsSubscription: "onlycumsSubscriptions",
        summoningCircle: "summoningCircles",
        elementalBracelet: "elementalBracelets",
      },
    };

    Object.entries(entityKeyUpdates).forEach(([entityGroupKey, entityGroup]) => {
      Object.entries(entityGroup).forEach(([oldEntityKey, newEntityKey]) => {
        const oldStorageKey = `${entityGroupKey}.${oldEntityKey}`;
        const newStorageKey = `${entityGroupKey}.${newEntityKey}`;

        const old = localStorage.getItem(oldStorageKey);

        if (old != null) {
          localStorage.setItem(newStorageKey, old);
        }
      });
    });
  },
  () => {
    // Add session times
    const sessionsAmountKey = "sessions.amount";
    const storedSessionsAmount = localStorage.getItem(sessionsAmountKey);

    if (storedSessionsAmount == null) {
      localStorage.setItem(sessionsAmountKey, "0");
    }
  },
];

export function applySaveMigrations() {
  const currentSaveVersion =
    localStorage.getItem("saveVersion") == null ? 0 : Number(localStorage.getItem("saveVersion"));

  if (currentSaveVersion < migrations.length) {
    for (let i = currentSaveVersion; i < migrations.length; i += 1) {
      migrations[i]();
    }
  }

  localStorage.setItem("saveVersion", String(migrations.length));
}
