import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("span", null, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.stringParts, (part, index) => {
      return (_openBlock(), _createElementBlock("span", {
        key: `${index}.${part.digit}`,
        class: _normalizeClass(part.class)
      }, _toDisplayString(part.digit), 3))
    }), 128))
  ]))
}