<template>
  <CumClicker />
</template>

<script lang="ts">
import { defineComponent } from "vue";
import CumClicker from "@/components/Game/CumClicker.vue";

export default defineComponent({
  name: "HomeView",
  components: {
    CumClicker,
  },
});
</script>

<style scoped lang="scss"></style>
