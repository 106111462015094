import { resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_NavBar = _resolveComponent("NavBar")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_FooterBar = _resolveComponent("FooterBar")!
  const _component_ModalContainer = _resolveComponent("ModalContainer")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_NavBar),
    _createElementVNode("main", {
      class: _normalizeClass({ gayge: _ctx.cheatsActive.gayge })
    }, [
      _createVNode(_component_router_view)
    ], 2),
    _createVNode(_component_FooterBar),
    _createVNode(_component_ModalContainer)
  ], 64))
}