import { CumBuilding, CumBuildingKey, useBuildingsStore } from "@/store/buildings";
import { CumCurrency, CumCurrencyKey, useCurrenciesStore } from "@/store/currencies";
import { Cost, useGameStore } from "@/store/game";
import { useUpgradesStore } from "@/store/upgrades";
import { CumUnit, CumUnitKey, TickEffect, useUnitsStore } from "@/store/units";

export function applyTickEffects(tickEffects: TickEffect[], amount: number, tick: number) {
  const { currencies } = useCurrenciesStore();
  const { upgrades } = useUpgradesStore();
  const { units } = useUnitsStore();

  for (let i = 0; i < tickEffects.length; i += 1) {
    const { type, item, upgrade, min, max } = tickEffects[i];

    let gain = randomUnitsPerMinuteToUnitsPerTick(min, max) * amount;

    if (upgrade != null) {
      if (upgrades[upgrade].amount > 0) gain *= 2 * upgrades[upgrade].amount;
    }

    if (type === "currencies") {
      currencies[item].purchase(gain);
    } else if (type === "units" && tick % 10 === 0) {
      if (Math.random() < 0.3) continue;
      gain = Math.floor(gain * 10);
      if (Math.random() < 0.2) gain = Math.floor(gain / 2);
      units[item].give(gain);
    }
  }
}

export function formatNum(num: number): string {
  if (num === 0) return "0";

  if (num < 0) {
    num *= -1;
  }

  if (num < 1) {
    return String(num.toFixed(1 - Math.floor(Math.log(Math.abs(num)) / Math.log(10))));
  }

  return String(
    Intl.NumberFormat("en-US", {
      notation: "compact",
      maximumFractionDigits: 1,
    }).format(num),
  );
}

export function getCostLabel(cost: Cost) {
  const { type, item, amount } = cost;

  let label = getLabel(type, item);
  label = upperCaseAndPluralize(label, amount);
  let amountStr = String(amount);

  if (amount % 1000 === 0) {
    amountStr = formatNum(amount);
  }

  return `${amountStr} ${label}`;
}

export function getCostLabels(cost: Cost[]) {
  return cost.map(getCostLabel).join(", ");
}

export function getInfoTextStrings(tickEffects: TickEffect[]) {
  const { upgrades } = useUpgradesStore();
  const strings: string[] = [];

  tickEffects.forEach(({ item, type, upgrade, min, max }, index) => {
    if (upgrade != null) {
      if (upgrades[upgrade].amount > 0) min *= 2 * upgrades[upgrade].amount;
      if (upgrades[upgrade].amount > 0) max *= 2 * upgrades[upgrade].amount;
    }

    let label = getLabel(type, item);
    label = upperCaseAndPluralize(label, 2);

    strings.push(`${min}-${max} ${label}`);

    if (index !== tickEffects.length - 1) {
      strings.push(", ");
    }
  });

  strings.push(" per minute");

  return strings.join("");
}

export function getLabel(
  type: "buildings" | "currencies" | "units",
  item: CumBuildingKey | CumCurrencyKey | CumUnitKey,
) {
  return getStoreFromType(type)[item].name;
}

export function getNormalizedCost(cost: Cost[]): number {
  let cumCost = 0;

  for (let i = 0; i < cost.length; i += 1) {
    const { type, item, amount } = cost[i];

    if (type === "currencies") {
      if (item === "cums") cumCost += amount;
      if (item === "cumMotes") cumCost += amount * 1_000_000_000;
      if (item === "cumCoin") cumCost += amount * 1_000_000_000 * 1_000_000_000;
    }
  }

  return cumCost;
}

export function getPerSecondStats(tickEffects: TickEffect[], amount: number) {
  const { upgrades } = useUpgradesStore();
  const strings: string[] = [];

  tickEffects.forEach(({ type, item, upgrade, min, max }) => {
    min *= amount;
    max *= amount;
    const label = getLabel(type, item);

    if (upgrade != null) {
      if (upgrades[upgrade].amount > 0) {
        min *= 2 * upgrades[upgrade].amount;
        max *= 2 * upgrades[upgrade].amount;
      }
    }

    const minPerSecond = min / 60;
    const maxPerSecond = max / 60;

    strings.push(`${formatNum(minPerSecond)}-${formatNum(maxPerSecond)} ${label}/s`);
  });

  return strings.join("\n");
}

export function getRandomPercentageOfAmount(
  minPercent: number,
  maxPercent: number,
  amount: number,
): number {
  const { tickRateFactor } = useGameStore();
  const base =
    Math.random() * ((maxPercent / 100) * amount - (minPercent / 100) * amount) +
    (minPercent / 100) * amount;

  return base * tickRateFactor;
}

export function getStoreFromType(type: "buildings" | "currencies" | "units") {
  const { buildings } = useBuildingsStore();
  const { currencies } = useCurrenciesStore();
  const { units } = useUnitsStore();

  const typeToStore: {
    buildings: typeof buildings;
    currencies: typeof currencies;
    units: typeof units;
  } = {
    buildings,
    currencies,
    units,
  };

  return typeToStore[type];
}

export function give(gainKey: string) {
  return function give(this: CumBuilding | CumCurrency | CumUnit, amount: number) {
    const { addSessionGain } = useGameStore();

    if ("max" in this) {
      const oldAmount = this.amount;
      this.amount = Math.max(0, Math.min(Number(this.max), this.amount + amount));
      addSessionGain(gainKey, this.amount - oldAmount);
    } else {
      this.amount = Math.max(0, this.amount + amount);
      addSessionGain(gainKey, amount);
    }

    if ("maxObtained" in this) {
      this.maxObtained = Math.max(this.maxObtained, this.amount);
    }
  };
}

function randomUnitsPerMinuteToUnitsPerTick(min: number, max: number): number {
  const { tickRateFactor } = useGameStore();
  const random = Math.random() * (max - min) + min;
  const base = random / 600;

  return base * tickRateFactor;
}

export function tryPurchase(cost: Cost[], numToPurchase: number): boolean {
  for (let i = 0; i < cost.length; i += 1) {
    const { type, item, amount } = cost[i];
    const store = getStoreFromType(type);
    if (store[item].amount < amount * numToPurchase) return false;
  }

  for (let i = 0; i < cost.length; i += 1) {
    const { type, item, amount } = cost[i];
    const store = getStoreFromType(type);
    store[item].amount -= amount * numToPurchase;
  }

  return true;
}

export function upperCaseAndPluralize(s: string, amount: number) {
  const split = s.split("");

  if (amount > 1 && split.at(-1) !== "s") split.push("s");
  split[0] = split[0].toUpperCase();

  return split.join("");
}

export function wait(time: number) {
  return new Promise<void>((resolve) => {
    setTimeout(() => {
      resolve();
    }, time);
  });
}
