<template>
  <div v-if="cheatsActive.cost || showErrorModal || cheatsActive.session" class="modal-container">
    <ErrorModal v-if="showErrorModal" @cancel="() => (showErrorModal = false)" />
    <CostModal v-else-if="cheatsActive.cost" />
    <SessionStatsModal v-else-if="cheatsActive.session" />
  </div>
</template>

<script lang="ts">
import { ref, watch } from "vue";

import { useCheatsStore } from "@/store/cheats";
import { useErrorsStore } from "@/store/errors";
import CostModal from "@/components/common/Modal/CostModal.vue";
import ErrorModal from "@/components/common/Modal/ErrorModal.vue";
import SessionStatsModal from "@/components/common/Modal/SessionStatsModal.vue";

export default {
  name: "ModalContainer",
  components: {
    CostModal,
    ErrorModal,
    SessionStatsModal,
  },
  setup() {
    const { cheatsActive } = useCheatsStore();
    const { errors } = useErrorsStore();
    const showErrorModal = ref(false);

    watch(errors, () => {
      showErrorModal.value = true;
    });

    return { cheatsActive, errors, showErrorModal };
  },
};
</script>

<style lang="scss">
.modal-container {
  position: absolute;
  height: 100%;
  width: 100%;
  z-index: 999;
  top: 0;
  left: 0;
  background-color: rgba($color: grey, $alpha: 0.5);
  display: flex;
  align-items: center;
  justify-content: center;

  .modal {
    height: 50%;
    width: auto;
    background-color: white;
    border: 2px dashed rgb(73, 73, 73);
    padding: 20px;
    overflow-y: hidden;
  }
}
</style>
