<template>
  <div id="side-bar">
    <div class="heading-container">
      <img class="corpa" src="@/assets/corpa-3x.png" alt="corpa" />
      <h1>Accounting</h1>
    </div>
    <!-- Stats Display -->
    <div class="sticky-currency" ref="stickyCurrency">
      <h1>Currency</h1>
      <template v-for="currency in currencies" :key="currency.name">
        <div class="currency-container" v-if="currency.isVisible">
          <span
            >{{ currency.maxLabel }} |
            {{ getPercentFull(currency.amount, Number(currency.max)) }}</span
          >
          <br />
          <span>
            {{ currency.name }}:
            <RainbowDigits :number="currency.amount.toFixed(2)" />
          </span>
          <template
            v-if="
              currency.name === 'Cums' &&
              currency.maxObtained >= 1_000_000_000 &&
              upgrades.autoCumdense.amount === 0
            "
          >
            <br />
            <button
              :disabled="currency.amount < 1_000_000_000"
              class="condense-button"
              @click="condenseCums"
            >
              Condense
            </button>
          </template>
          <template v-if="currency.name === 'Cum Motes' && currency.maxObtained >= 1_000_000_000">
            <br />
            <button
              :disabled="currency.amount < 1_000_000_000"
              class="condense-button"
              @click="tradeCums"
            >
              Trade
            </button>
          </template>
          <label v-else-if="currency.name === 'Cums' && upgrades.autoCumdense.amount === 1"
            ><br />Auto Condense <input v-model="upgrades.autoCumdense.active" type="checkbox"
          /></label>
        </div>
      </template>
    </div>
    <div class="stats-container">
      <h1
        :class="['expandable', { expanded: expandedState.borpaContainer }]"
        @click="toggleExpandable('borpaContainer')"
      >
        Borpas
      </h1>
      <div :class="['borpa-container', { ['section-expanded']: expandedState.borpaContainer }]">
        <template v-for="unit in units">
          <span class="unit" v-if="unit.isVisible" :key="unit.name">
            {{ unit.name }}: <RainbowDigits :number="unit.amount" /> /
            <RainbowDigits :number="Number(unit.max)" />
            <div v-if="unit.amount > 0">
              {{ getPerSecondStats(unit.tickEffects, unit.amount) }}
            </div>
          </span>
        </template>
      </div>
      <h1
        :class="['expandable', { expanded: expandedState.buildingContainer }]"
        @click="toggleExpandable('buildingContainer')"
      >
        Buildings
      </h1>
      <div
        :class="['building-container', { ['section-expanded']: expandedState.buildingContainer }]"
      >
        <template v-for="building in buildings">
          <span class="building" v-if="building.isVisible" :key="building.name">
            {{ building.name }}: {{ building.amount }}
          </span>
        </template>
      </div>
    </div>
    <!-- Purchasing Controls -->
    <div class="controls">
      <h2
        :class="['expandable', { expanded: expandedState.buildingControls }]"
        @click="toggleExpandable('buildingControls')"
      >
        Buy Buildings
      </h2>
      <div :class="['building-controls', { ['section-expanded']: expandedState.buildingControls }]">
        <template v-for="building in buildings" :key="building.name">
          <div v-if="building.isVisible" class="button-container">
            <button @click="building.purchase(1)">
              {{ building.name }}<br v-if="building.cost.length > 1" />
              ({{ getCostLabels(building.cost) }})
            </button>
            <button
              v-if="upgrades.multipleBuildingButtons.amount >= 1"
              @click="building.purchase(10)"
            >
              10x
            </button>
            <button
              v-if="upgrades.multipleBuildingButtons.amount >= 2"
              @click="building.purchase(100)"
            >
              100x
            </button>
            <button
              v-if="upgrades.multipleBuildingButtons.amount >= 3"
              @click="building.purchase(1000)"
            >
              1000x
            </button>
            <ToolTip v-if="building.infoText != null" :text="String(building.infoText)" />
          </div>
        </template>
      </div>
      <h2
        :class="['expandable', { expanded: expandedState.borpaControls }]"
        @click="toggleExpandable('borpaControls')"
      >
        Hire Borpas
      </h2>

      <div :class="['borpa-controls', { ['section-expanded']: expandedState.borpaControls }]">
        <template v-for="unit in units" :key="unit.name">
          <div v-if="unit.isVisible" class="button-container">
            <button @click="unit.purchase(1)">
              {{ unit.name }}<br v-if="unit.cost.length > 1" />
              ({{ getCostLabels(unit.cost) }})
            </button>
            <button v-if="upgrades.multipleBorpaButtons.amount >= 1" @click="unit.purchase(10)">
              10x
            </button>
            <button v-if="upgrades.multipleBorpaButtons.amount >= 2" @click="unit.purchase(100)">
              100x
            </button>
            <button v-if="upgrades.multipleBorpaButtons.amount >= 3" @click="unit.purchase(1000)">
              1000x
            </button>
            <ToolTip v-if="unit.infoText != null" :text="String(unit.infoText)" />
          </div>
        </template>
      </div>
      <h2
        :class="['expandable', { expanded: expandedState.upgradeControls }]"
        @click="toggleExpandable('upgradeControls')"
      >
        Buy Upgrades
      </h2>
      <div :class="['upgrade-controls', { ['section-expanded']: expandedState.upgradeControls }]">
        <template v-for="upgrade in sortedUpgrades" :key="upgrade.tier.name">
          <div v-if="upgrade.tier.isVisible" class="button-container">
            <button @click="upgrade.purchase()">
              {{ upgrade.tier.name }}<br v-if="upgrade.tier.cost.length > 1" />
              ({{ getCostLabels(upgrade.tier.cost) }})
            </button>
            <ToolTip v-if="upgrade.tier.infoText != null" :text="String(upgrade.tier.infoText)" />
          </div>
        </template>
      </div>
      <!-- Purchased Upgrades Display -->
      <h2
        :class="['expandable', { expanded: expandedState.purchasedUpgrades }]"
        @click="toggleExpandable('purchasedUpgrades')"
      >
        Purchased Upgrades
      </h2>
      <div
        :class="['purchased-upgrades', { ['section-expanded']: expandedState.purchasedUpgrades }]"
      >
        <template v-for="(upgrade, upgradeKey) in upgrades" :key="upgradeKey">
          <template v-for="(upgradeTier, index) in upgrade.tiers" :key="upgradeTier.name">
            <div v-if="upgrade.amount > index" class="button-container">
              {{ upgradeTier.name }}
              <ToolTip v-if="upgradeTier.infoText != null" :text="String(upgradeTier.infoText)" />
            </div>
          </template>
        </template>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Ref, computed, defineComponent, ref } from "vue";

import { useBuildingsStore } from "@/store/buildings";
import { useCurrenciesStore } from "@/store/currencies";
import { useUnitsStore } from "@/store/units";
import { UpgradeTier, useUpgradesStore } from "@/store/upgrades";
import { getNormalizedCost, getPerSecondStats, getCostLabels } from "@/util";
import RainbowDigits from "@/components/common/RainbowDigits.vue";
import ToolTip from "@/components/common/ToolTip.vue";

export default defineComponent({
  name: "SideBar",
  components: {
    RainbowDigits,
    ToolTip,
  },
  mounted() {
    if (this.$refs.stickyCurrency != null) {
      this.observer.observe(this.$refs.stickyCurrency as Element);
    }
  },
  unmounted() {
    if (this.$refs.stickyCurrency != null) {
      this.observer.unobserve(this.$refs.stickyCurrency as Element);
    }
  },
  setup() {
    const { buildings } = useBuildingsStore();
    const { currencies } = useCurrenciesStore();
    const { units } = useUnitsStore();
    const { upgrades } = useUpgradesStore();

    function getPercentFull(amount: number, max: number) {
      const percent = (amount / max) * 100;
      return `${percent.toFixed(1)}% full`;
    }

    const sortedUpgrades = computed(() => {
      const flatUpgrades: {
        tier: UpgradeTier;
        purchase: () => void;
      }[] = [];

      Object.entries(upgrades).forEach(([, upgrade]) => {
        upgrade.tiers.forEach((tier) => {
          flatUpgrades.push({ tier, purchase: upgrade.purchase.bind(upgrade) });
        });
      });

      return flatUpgrades.sort((a, b) => {
        return getNormalizedCost(a.tier.cost) - getNormalizedCost(b.tier.cost);
      });
    });

    function condenseCums() {
      if (currencies.cums.amount !== 1_000_000_000) return;
      currencies.cums.amount = 0;
      currencies.cumMotes.purchase(1);
    }

    function tradeCums() {
      if (currencies.cumMotes.amount !== 1_000_000_000) return;
      currencies.cumMotes.amount = 0;
      currencies.cumCoin.purchase(1);
    }

    const expandedState: Ref<{ [key: string]: boolean }> = ref({
      borpaContainer: true,
      buildingContainer: true,
      buildingControls: true,
      borpaControls: true,
      upgradeControls: true,
      purchasedUpgrades: true,
    });

    function toggleExpandable(section: string) {
      expandedState.value[section] = !expandedState.value[section];
    }

    const observer = new IntersectionObserver(
      ([e]) => {
        e.target.classList.toggle("is-pinned", e.intersectionRatio < 1);
      },
      {
        threshold: [1],
      },
    );

    return {
      buildings,
      condenseCums,
      currencies,
      expandedState,
      getCostLabels,
      getPerSecondStats,
      getPercentFull,
      observer,
      sortedUpgrades,
      toggleExpandable,
      tradeCums,
      units,
      upgrades,
    };
  },
});
</script>

<style scoped lang="scss">
#side-bar {
  height: 100%;
  min-width: 408px;
  max-width: 408px;
  padding: 0 8px;
  border-right: 2px solid black;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: stretch;
  overflow-y: scroll;
  overflow-x: hidden;

  h1,
  h2 {
    margin: 16px 0;
    font-weight: 600;

    &.expandable {
      cursor: pointer;
    }

    &.expandable::after {
      display: block;
      padding: 4px;
      width: 0.5rem;
      height: 0.7rem;
      margin-inline-start: 0.15rem;
      float: right;
      transform: rotate(-90deg);
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none' stroke='rgb(162, 175, 185)' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3E%3Cpolyline points='6 9 12 15 18 9'%3E%3C/polyline%3E%3C/svg%3E");
      background-position: right center;
      background-size: 1rem auto;
      background-repeat: no-repeat;
      content: "";
      transition: transform 0.2s ease-in-out;
    }

    &.expanded::after {
      transform: none;
    }
  }

  .sticky-currency {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    background-color: white;
    padding-bottom: 12px;
    position: sticky;
    top: -1px;
    z-index: 1;

    &.is-pinned {
      box-shadow:
        0px 4px 5px -5px rgba(13, 13, 13, 0.05),
        0px 4px 5px -5px rgba(13, 13, 13, 0.2);
    }

    .currency-container {
      margin-bottom: 8px;
    }
  }

  .borpa-container,
  .building-container,
  .building-controls,
  .borpa-controls,
  .upgrade-controls,
  .purchased-upgrades {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    height: 0;
    overflow: hidden;

    &.section-expanded {
      height: auto;
      overflow: visible;
    }
  }

  .heading-container {
    width: 100%;
    height: 64px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .corpa {
      height: 32px;
      margin: 4px 0;
    }

    h1 {
      margin-top: 0;
    }
  }

  .stats-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    .unit {
      margin-bottom: 8px;
      white-space: pre-line;
    }

    .building {
      margin-bottom: 4px;
    }

    .condense-button {
      &:disabled {
        border-color: grey;
      }
    }
  }

  .controls {
    height: auto;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    .button-container {
      display: flex;
      align-items: center;
      justify-content: flex-start;

      button {
        max-width: 93%;
      }

      button:not(:first-child) {
        margin-left: 4px;
      }

      .info-icon {
        cursor: help;
        font-size: 24px;
        padding-left: 6px;
        padding-bottom: 2px;
      }
    }

    .purchased-upgrades {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
    }
  }
}
</style>
