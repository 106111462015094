<template>
  <footer :class="{ gayge: cheatsActive.gayge }">
    <template v-if="cheatsActive.tickRate">
      <label>
        Tick Rate (ms)
        <input class="tick-rate" type="number" min="10" max="100000" v-model="tickRate" />
      </label>
    </template>
    Made with shame and disgust by Jaygles#9462&nbsp;&nbsp;
    <button @click="resetGame">Reset</button>
  </footer>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { useGameStore } from "@/store/game";
import { useCheatsStore } from "@/store/cheats";
import { storeToRefs } from "pinia";

export default defineComponent({
  name: "FooterBar",
  setup() {
    const gameStore = useGameStore();
    const cheatsStore = useCheatsStore();
    const { resetGame } = gameStore;
    const { cheatsActive } = storeToRefs(cheatsStore);
    const { tickRate } = storeToRefs(gameStore);

    return { resetGame, cheatsActive, tickRate };
  },
});
</script>

<style scoped lang="scss">
footer {
  height: 32px;
  width: 100%;
  border-top: 1px black dashed;
  display: flex;
  align-items: center;
  justify-content: center;

  .tick-rate {
    width: 60px;
    margin-right: 10px;
  }
  &.gayge {
    background: linear-gradient(
      90deg,
      rgba(255, 0, 0, 0.4) 0%,
      rgba(255, 154, 0, 0.4) 10%,
      rgba(208, 222, 33, 0.4) 20%,
      rgba(79, 220, 74, 0.4) 30%,
      rgba(63, 218, 216, 0.4) 40%,
      rgba(47, 201, 226, 0.4) 50%,
      rgba(28, 127, 238, 0.4) 60%,
      rgba(95, 21, 242, 0.4) 70%,
      rgba(186, 12, 248, 0.4) 80%,
      rgba(251, 7, 217, 0.4) 90%,
      rgba(255, 0, 0, 0.4) 100%
    );
  }
}
</style>
